@import '../../variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('//db.onlinewebfonts.com/c/7a2f2060217a4d3baf9259272400e8a0?family=Neuzeit+Office');
.reviews{
    width: 100%;
    height: 100%;
    margin: 75px 0px;
    background-color: #fcfaf9;
  
    .reviews_container {
        width: 100%;
        padding: 75px 0px;
        .page-width {
            max-width: 1500px;
            padding: 0px 25px;
            margin-left: auto;
            margin-right: auto;
            .section-header {
                width: 100%;
                margin-bottom: 50px;
                text-align: center;
                display: flex;
                justify-content: center;
                h2 {
                    text-transform: uppercase;
                    font-family: "Neuzeit Office" , sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    color: $colorTextBody ;
                }
            }
            .slider-swiper {
                width: 100%;
                .swiper {
                    width: 100%;
                    padding-bottom: 50px;
                  }
                  .swiper-slide {
                    background-position: center;
                    background-size: cover;
                    padding: 30px;
                    width:  30%;
                    min-height: 250px;
                    background-color: white;
                    box-shadow: 0 10px 25px #0000001a;
                    color: $colorTextBody;

                    .raiting {
                        span {
                            letter-spacing: 0.2em;
                            font-size: 20px;
                        }
                    }
                    .text-description  {
                        p {
                            font-family: $primary-font-family;
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0.1em;
                            line-height: 24px;
                            color: rgb(82, 48, 6);
                        }
                    }
                    cite {
                        font-family: $primary-font-family;
                        font-weight: 600;
                    }
                    #title {
                        font-family: $primary-font-family;
                        font-size: 15px;
                        margin-top: 5px;
                    }

                    img {
                        display: block;
                        width: 100%;
                      }
                  }
                  
            }
        }

    }
}

@media screen and (max-width: 850px) {
    .slider-swiper {  .swiper-slide {
        width: 60%!important;
    }
}
}

@media screen and (max-width: 769px) {
    .slider-swiper {   .swiper-slide {
        width: 95%!important;
    }
}
}
:root {
    --swiper-theme-color : rgb(136, 101, 36) ;
}