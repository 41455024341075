@import '../../variables.scss';
.footer {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(115 85 29 / 93%);
    flex-direction: column;
    span {
        padding: 20px;
        font-family: $primary-font-family;
        color: white;
        font-size: 13.5px;
    }
    .footer_container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;
        .footer_grid_item  , .footer_grid_item_img{
            width: 23%;
            display: flex;
            padding: 10px;
            flex-direction: column;
            justify-content: center;
            font-family: "Montserrat", sans-serif;
            padding: 10px;
            min-width: 225px;
            max-width: 240px;
     

            .input_news {
                width: 100%;
                height: 35px;
                background-color: transparent;
                border-bottom: 3px solid white;
                display: flex;
                align-items: center;
                justify-content: space-between;                

                input {
                    border: 0px;
                    background-color: transparent;
                    padding: 0px;
                    caret-color:white;
                    color: rgb(255, 227, 200);
                    letter-spacing: 0.5px;
                    font-family: $primary-font-family;
                    width: 50%;
                }
                span {
                    padding: 0px;
                }
            }
            span {
                font-size: 12px;
                color: white;
                text-transform: uppercase;
                letter-spacing: 2.5px;
                line-height: 17px;
                padding: 0px;
            }
            #text {
                font-size: 12.5px;
                color: white;
                padding: 20px 0px;
                text-transform: lowercase;
                letter-spacing: 0.2px;

            }

            img {
                width: 90%;
                max-width: 50%;
                max-height: 130px;
                cursor: pointer;
            }
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                gap: 8px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;


            }
            li {
                margin: 0px;
                padding: 0px;
                list-style: none;
            }
            a {
                text-decoration: none;
                font-family: $primary-font-family;
                color: white;
                font-weight: 500;
                font-size: 12.5px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
            }
            a:hover {
                color: bisque;

            }
        }
    }

}
@media screen and (max-width:900px) {

    .footer .footer_container {
        justify-content: center;
        gap: 20px;
    }

    
}
@media screen and (max-width:1100px) {

    .footer .footer_container {
        gap: 20px;
    }
    
}

@media screen and (max-width:550px) {

    .footer {
        padding: 20px;
    }
    
}

@media screen and (max-width:600px) {

    .footer {
        padding: 20px;
    }
    .footer .footer_container .footer_grid_item, .footer .footer_container .footer_grid_item_img {

        min-width: 190px;
    }
    .footer .footer_container .footer_grid_item a, .footer .footer_container .footer_grid_item_img a {
        font-size: 11px;
    }
    .footer .footer_container .footer_grid_item span, .footer .footer_container .footer_grid_item_img span {
        font-size: 11px;
    }
    .footer .footer_container .footer_grid_item #text, .footer .footer_container .footer_grid_item_img #text{
        font-size: 12px;
    }
    
}

@media screen and (max-width:500px) {
        .footer .footer_container .footer_grid_item ul, .footer .footer_container .footer_grid_item_img ul {
            margin-left: auto;
            margin-right: auto;
        }
        .footer .footer_container .footer_grid_item, .footer .footer_container #newsletter {
            width: 100%!important;
        }

}

@media screen and (min-width:1600px) {
    
    .footer .footer_container {
        max-width: 1500px;
        justify-content: space-between;
    }
}