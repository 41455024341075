@import '../../variables.scss';
.newsLetterOpen{
    opacity: 0!important;
    transition: opacity 0.7s ease;
    z-index: -1!important;
    transform: scale(0)!important;
}
.newsLetter {
    opacity: 1;
    transition: opacity 0.7s ease;

}
.newsLetter , .newsLetterOpen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e6e6e6a8;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    .newsLetter_container{
        max-width: 90%;
        width: 800px;
        height: 320px;
        background: white;
        display: flex;
        font-family: $primary-font-family;
        img {
            width: 100%;
            object-fit: cover;
        }
        .newsLetter_content {
            padding: 25px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            p {
                padding: 12px 23px;
                background-color:$colorTextBody ;
                font-weight: 800;
                font-family: $primary-font-family;
                color: white;
                font-size: 14px;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin: 0px;
                max-width: 100%;
                cursor: default;
                
            }
            p:hover {
                background-color: rgb(164, 132, 72);
                position: relative;
                transition: background .2s ease 0s;

            }
            .success{
                width: 100%;
                text-align: center;
                color: #56ad6a;
                border: 1px solid #47ad5f;
                padding: 5px;
                font-size: 14px;
                background-color: #ecfef0;
            }
            .input_container {
                width: 100%;
                border: 1px solid $border;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .iconLoad {
                    background-color: rgb(200, 171, 117);
                    color: white;
                    font-size: 30px;
                    padding: 7px 0px;
                    cursor: pointer;
                    transition: 0.6s ease;
                    width: 25%;

                }
                .icon {
                    width: 25%;
                    background-color: $colorTextBody;
                    color: white;
                    font-size: 30px;
                    padding: 7px 0px;
                    cursor: pointer;
                    transition: 0.6s ease;
                }
                .icon:hover {
                    background-color: rgb(164, 132, 72);
                }
                .icon:focus {
                    background-color: rgb(164, 132, 72);
                }
                input {
                    width: 70%;
                    height: 97%;
                    border: 0px;
                    font-family: $primary-font-family;
                    padding: 0px 10px;
                    margin: 0px;

                }
                input:focus {
                    outline-width: 0px;

                }
                input::placeholder {

                }
            }
            .section_close {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
            span {
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
            .title {
                font-size: 16px;
                color: $colorTextBody;
                font-weight: 600;
                max-width: 75%;

            }
            .description {
                font-size: 14px;
                color: $colorTextBody;
                font-weight: 400;
                max-width: 90%;
                line-height: 24px;
            }
        }
    }
}

@media screen and (max-width:850px) {

    .newsLetter .newsLetter_container img, .newsLetterOpen .newsLetter_container img{
        display: none;
    }
    .newsLetter .newsLetter_container .newsLetter_content .input_container  {
        max-width: 400px;
    }
    .newsLetter .newsLetter_container .newsLetter_content p {
        font-size: 14px;
    }
    .newsLetter .newsLetter_container{
        height: auto;
        margin-bottom: 30px;

    }
    .newsLetter  {
        align-items: flex-end;
    }

}