@import '../../variables.scss';

.bag_page_open{
    z-index: 1000;
    background-color: rgba(204, 201, 201, 0.405);
    overflow-y: hidden;
    position: relative;


}
.bag_page{
        z-index: -1;
        overflow-y: hidden;
        transition:  0.8s ease;
        overflow-x: hidden;
        background-color: rgba(204, 201, 201, 0);
        position: relative;
}
.bag_page , .bag_page_open {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    
    .bag_container  , .bag_container_open{
        z-index: 100;
        width: 380px;
        height: 100%;
        background-color: white;
        z-index: 40;
        transition:0.8s ease;
        position: fixed;
        font-family:'Montserrat', sans-serif;
        right: -470px;
        background-color: rgba(87, 62, 16, 1) ;
        padding: 30px;
        
        .bag_wrapper{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .bag_header_show{
                transform: translateY(0px);
                opacity: 1;
                transition: 0.5s ease;
            }
            .bag_header  {
                opacity: 0;
                transform: translateY(70px);
            }
            .bag_header , .bag_header_show {
                width: 100%;
                display: flex;
                height: 5px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                height: 10%;
                span {
                    font-size: 20px;
                    color: white;
                    text-transform: uppercase;
                    font-weight: 400;
                }
                #close {
                    color: white;
                    font-size: 17px;
                    cursor: pointer;
                }
            }
            .bag_bodyShow {
                transition: 1s ease-in-out;

                opacity: 1;
                transform: translateY(0px);
            }
            .bag_body {
                opacity: 0;
                transform: translateY(70px);
                
            }
            .bag_body , .bag_bodyShow {
                width: 100%;
                overflow: auto;
                display: flex;
                overflow: hidden;
                display: flex;
                gap: 20px;
                border-bottom: 1px solid #ffd5b2;
                padding: 15px 0px;
            
                .cart_image{
                    flex: 0 0 100px;
                    img {
                        width: 100%;
                        display: block;
                    }
                    img:hover {
                        opacity: 0.5;
                    }
                }
                .cart__item-details{
                    flex: 1 1 auto;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: space-between;
                    .cart__item-top {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        height: 50%;
                        span , #size{
                            color: white;
                            font-size: 14px;
                            letter-spacing: 0.9px;
                            font-weight: 500;
                        }
                         a {
                            margin: 0px;
                            #span {
                                margin-top: -10px;
                            }
                         } 
                        span:hover  {
                            color : #d2a84e;
                            cursor: pointer;
                            transition: 0.4s ease;
                            
                        }
                        #taille {
                            color: white;
                            font-size: 12px;
                            letter-spacing: 0.9px;
                            font-weight: 600;
                        }
                    }
                    .cart__item-bottom {
                        display: flex;
                        justify-content: space-between;
                        height: 50%;
                        display: flex;
                        font-family: $primary-font-family;
                        .product_quantity{
                            display: flex;
                            align-items: center;
                            color: white;
                            gap: 10px;
                            font-size: 14px;
                            cursor: default;
                            #icon:hover {
                                background-color: white;
                                color: $colorTextBody;
                            }
                            #icon {
                                padding: 5px;
                                cursor: pointer;
                            }
                        }
                        .product_price {
                            display: flex;
                            align-items: center;
                            span {
                                font-size: 14.5px;
                                font-weight: 400;
                                color: white;
                            }
                        }


                    }
                }
            }
            .bag_checkout_open  {
                opacity: 1;
                transform: translateY(0px);
                transition: 1s ease-in-out;
              }
              .bag_checkout  {
                opacity: 0;
                transform: translateY(70px);
              }
            .bag_checkout  , .bag_checkout_open {
                height: 15%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .subtotal{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0px;
                    color: white;
                    font-family: $primary-font-family;
                    span {
                        font-size: 12px;
                        font-weight: 350;
                        letter-spacing: 2px;
                    }
                    #price {
                        font-weight: 400;
                        font-size: 13.5px;
                        
                    }
                }
                .button_checkout {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    height: 40px;
                    background-color: #fcfaf9;
                    .loader {
                        color: rgba(87, 62, 16, 1)  ;
                        animation: load 0.7s  linear infinite;
                        font-size: 22px;
                    }

                    span {
                        width: 100%;
                        height: 100%;
                        font-size: 14px;
                        letter-spacing: 0.3em;
                        color: rgba(87, 62, 16, 1)  ;
                        text-transform: uppercase;
                        font-weight: 900;
                        line-height: 1.42;
                        background-color: transparent;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .button_checkout:hover span {
                    transition: 0.5s ease;
                    background-color:rgba(87, 62, 16, 0.93)  ;
                    color: rgb(66, 46, 8);

                }
                .button_checkout:hover {
                    background-color: #fcfaf9;
                }
            }
        }
    }
    .bag_container_open{ 
        transform: translateX(-470px);
        z-index: 1px;
        z-index: 1000;
        max-width: 400px;
        transition: transform 0.5s ;
        opacity: 1;
    }
    .bag_container {
        transform: translateX(0px);
        z-index: 1000;
        transition: transform 0.5s ;
        position: absolute;
        opacity: 1;



    }

}
 .empty {
  
    transform: translateY(100px);
    opacity: 0;
}
.emptyOpen {
    transform: translateY(0px);
        transition: transform 0.7s ;
        opacity: 1;
        color: white;
        font-family: $primary-font-family;
        font-size: 16px;



}


@media screen and (min-width:850px) {
    .scroll_container::-webkit-scrollbar {
        width: 10px;
        padding-right: 5px;
      }
      
      /* Track */
      .scroll_container::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
      }
       
      /* Handle */
      .scroll_container::-webkit-scrollbar-thumb {
        background: rgb(169, 133, 67); 
        border-radius: 10px;
      }
}


@media screen and (max-width:769px) {

    .bag_page .bag_container, .bag_page .bag_container_open, .bag_page_open .bag_container, .bag_page_open .bag_container_open {

        width: 320px;
        max-width:320px;

    }
    .bag_container  , .bag_container_open{ 

        right: -400px;

    }
    
}

@media screen and (max-width:400px) {
    .bag_page .bag_container, .bag_page .bag_container_open, .bag_page_open .bag_container, .bag_page_open .bag_container_open {

        max-width:80%;
        width: 80%;
        padding: 20px;
    }   
    
}
@media screen and (max-width:350px) {
    .bag_page .bag_container, .bag_page .bag_container_open, .bag_page_open .bag_container, .bag_page_open .bag_container_open {

        max-width:80%;
        width: 80%;

    }
    
}

@media screen and (min-width:1300px) {
    .bag_page .bag_container, .bag_page .bag_container_open, .bag_page_open .bag_container, .bag_page_open .bag_container_open {
        width: 500px;
        right: -561px;
    }
    .bag_page .bag_container_open, .bag_page_open .bag_container_open {
        max-width: 500px;
    }
    .bag_page .bag_container_open, .bag_page_open .bag_container_open {
        transform: translateX(-561px);
}
}


@keyframes bagShow { 
    from {
        opacity: 0;
}
to {
    opacity: 1;
}
    
}
@keyframes load {

    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
    
}