@import '../../variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('//db.onlinewebfonts.com/c/7a2f2060217a4d3baf9259272400e8a0?family=Neuzeit+Office');

.related{
    width: 100%;
    height: 100%;
    margin: 75px 0px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    .related_container {
        width: 100%;
        padding: 25px 0px;
        .page-width {
            padding: 0px 25px;

            .section-header {
                width: 100%;
                margin-bottom: 25px;
                text-align: center;
                display: flex;
                justify-content: center;
                h2 {
                    text-transform: uppercase;
                    font-family: "Neuzeit Office" , sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    color: $colorTextBody ;
                }
            }
            .button_back {
                width: 100%;
                margin-bottom: 25px;
                display: flex;
                align-items: center;
                justify-content:center ;
                .button_container {
                    padding: 8px 30px;
                    margin-top: 40px;
                background-color: $colorTextBody ;
                display: flex;
                align-items: center;
                justify-content: space-between ;
                cursor: pointer;
                gap: 10px;
                transition: 0.4s ease-in;
                min-width: 220px;

                    span {
                        color: white;
                        text-transform: uppercase;
                        font-weight: 600;
                        letter-spacing: 2.5px;
                        font-family: $primary-font-family;
                        font-size: 14px;
                    }
                    #icon {

                        font-size: 30px;
                        color: white;
                        font-weight: 200;
                    }
                }
                .button_container:hover {
                    background-color: rgba(130, 97, 32, 0.8509803922);
                }
            }
            .app__gallery-images {
                flex: 1;
                display: flex;
                flex-direction: row;
                max-width: 100%;
                position: relative;
                padding: 20px;
            }
            
            .app__gallery-images_container {
                display: flex;
                flex-direction: row;
                width: max-content;
                overflow-x: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;  
            }
            
            .app__gallery-images_container::-webkit-scrollbar {
                display: block;
            }
            
            .app__gallery-images_card {
                position: relative;
                min-width: 19%;
                max-height: 360px;
                margin-left: 10px;
                      display: flex;
                flex-direction: column;
                align-items: center;
                font-family: $primary-font-family;
                overflow: hidden;
                .infos_product {
                    z-index: 10;
                    background: white;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    
                    p {
                        margin: 5px 0px;
                        text-align: center;
                        font-size: 13px;
                        color:$colorTextBody;
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 0.08em;
                        transition: 0.4s ease;
                    }
                    p:hover {
                        color:#d2a84e ;
                        cursor: pointer;
                    }
                    span {
                        font-size: 13px;
                        color:$colorTextBody;
                        font-weight: 400;
    
                    }
                    
                 

                }
             
                .list_icons {
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    bottom: 40px;
                    opacity: 1;
                    transition: 0.4s ease-in-out;
                    z-index: 1;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    gap: 20px;
                    
                    .icon_bi {
                        opacity: 0;
                        transition: 0.4s ease-in-out;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #c2a394;
                        z-index: 1;
                        #icon {
                            color: white;
                            margin: 5px;
                            font-size: 18px;

                        }

                    }
                    .icon_bag{
                        
                        opacity: 0;
                        transition: 0.6s ease-in-out;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: #c2a394;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 1;
                        #icon {
                            color: white;
                            margin: 5px;
                            font-size: 18px;
                        }
                    }
                    .icon_bi:hover {
                        background-color: #ad613c;
                        transition: 0.2s ease;
                        cursor: pointer;
                    }
                    .icon_bag:hover {
                        background-color: #ad613c;
                        transition: 0.2s ease;
                        cursor: pointer;
                    }
                    li[data-title] {
                        position: relative;
                    }
                    
                    li[data-title]:hover::before {
                        content: attr(data-title);
                        position: absolute;
                        bottom: 40px!important;
                        display: flex;
                        padding: 3px 6px;
                        border-radius: 2px;
                        background: black;
                        color: #fff;
                        font-size: 12px;
                        font-family: sans-serif;
                        white-space: nowrap;
                        z-index: 50!important; /*optional styling*/
                        left: 0;
                        width: max-content;
                        justify-self: center;
                        margin-left: -20px;
                        font-family: $primary-font-family;

                    }
                    li {
                        margin: 0px;
                        padding: 0px;
                    }
                    
                }
            }
            .app__gallery-images_card:hover .list_icons .icon_bi{
                opacity: 1;
                transform: translateY(-30px);
        }
            .app__gallery-images_card:hover .list_icons{
                opacity: 1;
                transform: translateY(-30px);
        }
            .app__gallery-images_card:hover .list_icons .icon_bag {
                    opacity: 1;
                    transform: translateY(-30px);
            }
            
            .gallery__image-icon {
                position: absolute;
                color: #fff;
                font-size: 2rem;
                opacity: 0;
                transition: .5s ease;
                cursor: pointer;
            }
            
            .app__gallery-images_card img {
                width: 100%;
                max-height: 85%;
                object-fit: cover;
                opacity: 1;
                transition: .5s ease;
          
            }
            .app__gallery-images_card .gallery_image1 {
                display: none;
            }
            .gallery_image {
                display: block;
            }
            .app__gallery-images_card:hover .gallery_image {
                display: none;
            }
              .app__gallery-images_card:hover .gallery_image1 {
                display: block;
                transition: display 0.5s ease;
            }
            
            .app__gallery-images_card:hover .gallery__image-icon {
                opacity: 1;
               
            }
            
            .app__gallery-images_arrows {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            
                padding: 0 1rem;
                position: absolute;
                bottom: 5%;
            }
            
            .gallery__arrow-icon {
                color: var(--color-golden);
                font-size: 2rem;
                cursor: pointer;
                background: var(--color-black);
                border-radius: 5px;
            }
            
            .gallery__arrow-icon:hover {
                color: var(--color-white);
            }
            
        }
}
}
@media screen and (min-width: 2000px) {
    .app__gallery-content button {
        margin-top: 2rem;
    }

    .app__gallery-content {
        min-width: 1000px;
        padding-right: 4rem;
    }

    .app__gallery-images_card {
        min-width: 400px;
        height: 547px;
    }
}

@media screen and (max-width: 1150px) {
    .app__gallery {
        flex-direction: column;
    }

    .app__gallery-images {
        max-width: 100%;
        margin: 5rem 0;
    }
}

@media screen and (max-width: 850px) {
    .app__gallery {
        padding: 4rem 0 4rem 4rem;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
        padding: 4rem 0 4rem 2rem;
    }  
    
    .app__gallery-content {
        min-width: 100%;
    }

    .app__gallery-images_card {
        min-width: 240px;
        height: 320px;
    }
}
@media screen and (max-width:769px) {

    .app__gallery-images_card {
        min-width: 220px!important;
        min-height: 260px!important;
    }

 
    .related .related_container .page-width .app__gallery-images{
        overflow: scroll;
    }
}

@media screen and (max-width: 550px) {

    .related .related_container .page-width .app__gallery-images_card .list_icons {
        height:auto ; 
        bottom: 29px;
    }
    
}

