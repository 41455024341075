

@import '../../variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');


.review_section .riview_order {
    font-size: 20px;
    font-family: "Segoe UI", "Roboto", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 600;
    color: rgb(51, 51, 51);
    letter-spacing: 0.5px;
}
.review_section {

    display: flex;
    flex-direction: column;
    gap: 20px;

.review_infos {
    display: flex;
    flex-direction: column;
    gap: 13px;
    border-radius: 5px ;
    border: 1Px solid rgba(85, 85, 85, 0.22) !important;
    padding: 13px 20px;
    .divider{
        height: 0.5px;
        width: 90%;
        margin: auto;
        background-color:rgba(85, 85, 85, 0.22)  ;
    }
    .review_infos_items {
        display: flex;
        align-items: center;
        gap: 25px;
        cursor: pointer;

        span {
            color: rgb(117, 116, 114);
            font-size: 12.5px;
            font-weight: 400;
            width: 80px;             
        }
        input {
            background-color: white;
            -webkit-appearance: none;
            height: 18px;
            margin-block-start: .14285714285714285em;
            margin-top: .14285714285714285em;
            width: 18px;
            border-radius: 50%;
            background-clip: padding-box;
            border-style: solid;
            border-width:1px;
            transition: 0.2s ease;
            cursor: pointer;
        }
        input:checked {
            border: .42857142857142855em rgb(113, 82, 15) solid;
            transition: 0.2s ease;

        }
        .review_values {
            width: 100%;
            display: flex;
            justify-content: space-between;
            span {
            color:#545454;
            font-weight: 400;
            font-size: 14px;
            width: max-content;    
            }
            .edit {
                color:rgb(83, 55, 0); 
                font-size: 12px;
                cursor: pointer;

            }
            .value{
                color:rgb(83, 55, 0); 
                font-weight: 600;
                font-family: 'Lato', 'Helvet';
                font-size: 13px;
            }
        }
    }
    
}
}

.infos_container {
    max-width: calc(462px + 5vw);
    padding: 0px;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
.carte_items{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1.3px solid lightgray;
    padding-bottom: 15px;
    .cart__item-details {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cart__item-left {
            display: flex;
            gap: 15px;
            align-items: center;
            .cate_image {
                padding: 0px 11px;
                border-radius: 10px;
                border: 1px solid lightgray;
                display: flex;
                justify-content: center;
                position: relative;
                img {
                    max-height: 55px;
                }
                .quantity {
                    position: absolute;
                    top: 0;
                    margin-top: -13px;
                    margin-right: -5px;
                    right: 0;
                    padding: 4px 8px;
                    background-color: gray;
                    color: white;
                    border-radius: 50%;
                    font-size: 10.5px;
                    font-weight: 500;
                }
            }
            p {
                margin: 0px;
                        font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                color: rgb(47,47,47);
                font-weight: 500;
                letter-spacing: 0.5px;
                font-size: 14px;
                text-transform: capitalize;

            }
            span {
                        font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                color: rgb(110,110,110);
                font-weight: 400;
                font-size: 12px;
                text-transform: capitalize;
                margin-top: -10px;
                letter-spacing: 0.3px;
            }
        }
        .cart__item-right {

            span {
                        font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                font-size: 14px;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: 500;
                color: rgb(47,47,47);

            }

        }
    }
    }
    .items_total{
        padding: 30px 0px;
        display: flex;
        flex-direction: column;
        gap: 13px;
        border-bottom: 1.3px solid lightgray;

        .section {
            display: flex;
            justify-content: space-between;
            width: 100%;
                    font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
            align-items: center;
            span {
                     font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                font-size: 14.2px;
                letter-spacing: 0.3px;
                color:rgb(89, 89, 89);
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: 8px;
            }
            #icon {
                font-size: 18px;
                cursor: pointer;
            }
            .subtotal {
                        font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                font-size: 14px;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: 500;
                color: rgb(47,47,47);
               

            }
        }
    }
    .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0px;
        span {
                font-weight: 500;
                color: rgb(47,47,47);
                letter-spacing: 0.5px;
                font-size: 15px;

        }
        .total_item {
            font-size: 26px;
        }
        
    }
}
