@import '../../variables.scss';

.dilevery {
width: 100%;

.size_wrapper {
    height: 100%;
    display: flex;
    padding: 15px;
    justify-content: center;
    margin-top: 100px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    min-height: 700px;
    padding: 10px 0px;
    .size_container {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 15px 30px;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        margin: 35px 0px;
        .title {
            font-family: $primary-font-family;
            color: rgb(49, 31, 5);;
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0px 0px 25px 0px;
        }
        .size_left  {
            display: flex;
            flex-direction: column;
            gap: 20px;
           width: 90%;
           max-width: 1000px;
           img {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
            padding: 25px 0px;
           }
            .size_title_section {
                gap: 10px;
                display: flex;
                flex-direction: column;
                .long {
                    font-size: 14px;
                    margin: 0px 0px 15px 0px;
                }
                h4 {
                    font-family:$primary-font-family ;  
                    font-size: 18px;
                    color: rgb(105, 73, 28);
                    margin: 0px;
                    font-weight: 600;
              }
              span {
                font-size: 13.5px;
                font-family: $primary-font-family;
                color: rgb(49, 31, 5);
                font-weight: 500;
                line-height: 20px;
              }
              .contact {
                font-weight: 700;
                color: rgb(49, 31, 5);

              }
            }
      

        }

    }
}


}
@media screen and (max-width:450px) {
    .size_guide .size_wrapper .size_container .size_left .size_title_section h4 {
        font-size: 20px;
    }
    
}
@media screen and (max-width:500px) {
    .dilevery .size_wrapper .size_container .size_left{
        width: 100%;
    }
    
}