
@import '../../variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');
.payement_section {
   width: 100%;
    height: auto;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

    .paiement{
        font-size: 19px;
        font-weight: 400;
        letter-spacing: 0.3px;
        padding: 0px;
        margin: 0px;
    }
    .titles {
        display: flex;
        flex-direction: column;
        gap: 7px;
        width: 100%;
        .paiement_method_container{
            height: auto;
            border: 1Px solid rgba(85, 85, 85, 0.22) !important;
            margin-top: 4px;
           border-radius: 5px ;
           .paiement_method_dropped {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: opacity 0.5s ease, max-height 0.5s ease;

           }
           .showDropped {
            padding: 20px;
            opacity: 1;
            max-height: 500px; /* Hauteur maximale du dropdown */
            transition: opacity 0.3s ease, max-height 0.3s ease;
            animation: fadeIn 0.3s;
           }
              .checkedOption {
                    border: 1.5px solid rgba(53, 45, 2, 0.87) ;
                    border-radius: 5px 5px 0px 0px;
                    background-color: #8657124a;
                  }

            .paiement_method_wrapper {
                display: flex;
                flex-direction: column;
                  padding: 20px ;
                  z-index: 2;
               
                .wrapper_items{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;
                    .paiement_values {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: rgba(53, 45, 2, 0.87);
                        span {
                            font-size: 14px;
                            font-weight: 500;
                        }
                        img {
                            max-width: 100px;
                        }
                    }
                }
            }
            
        }
    }
    .subscription{
        font-size: 14px;
        color: rgba(53, 45, 2, 0.87);
    }
    .payement_infos {
        display: flex;
        flex-direction: column;
        gap: 13px;
        border-radius: 5px ;
        border: 1Px solid rgba(85, 85, 85, 0.22) !important;
        padding: 13px 20px;
        .divider{
            height: 0.5px;
            width: 90%;
            margin: auto;
            background-color:rgba(85, 85, 85, 0.22)  ;
        }
        .payement_infos_items {
            display: flex;
            align-items: center;
            gap: 25px;
            cursor: pointer;

            span {
                color: rgb(117, 116, 114);
                font-size: 12.5px;
                font-weight: 400;
                width: 70px;             
            }
               .payement_values {
                width: 100%;
                display: flex;
                justify-content: space-between;
                span {
                color:#545454;
                font-weight: 400;
                font-size: 14px;
                width: max-content;    
                }
                .edit {
                    color:rgb(83, 55, 0); 
                    font-size: 12px;
                    cursor: pointer;

                }
                .value{
                    color:rgb(83, 55, 0); 
                    font-weight: 600;
                    font-family: 'Lato', 'Helvet';
                    font-size: 13px;
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {

    .payement_section .payement_infos .payement_infos_items .payement_values span {
        width: 70%!important;
        text-align: initial;
    }
    .payement_section .payement_infos .payement_infos_items .payement_values .edit{
        width: max-content!important;
    }
}
    

@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }