@import  '../../variables.scss' ;
.collection-grid {
    width: 80%;
    float: left;
     z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
.collection-filter{
    width: 88%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filter-sort {
        height: 30px;
       width: 200px;
       border: 1px solid $border;
       padding: 5px;
        background-color: white;

        select {
            color: $colorTextBody;
            font-family: $primary-font-family;
            font-size: 16px;
            letter-spacing: 0.5px;
            height: inherit;
            width: inherit;
            border: 0px;
          background-color: white;

        }
    }
    .number_results {
        padding: 10px;
        display: flex;
        span {
            font-family: $primary-font-family;
            font-size: 14px;
            letter-spacing: 0.5px;
            color: $colorTextBody;
            font-weight: 500;
        }
    }
    .filter-section {
        width: 200px;
        border: 1px solid $border;
        height: 30px;
        display: flex;
        padding: 5px;
        align-items: center;
        background-color: rgba(157, 107, 33, 0.7294117647);
        display: none;
        #icon {
            color:#ffe2b3;
            font-size: 24px;
        }
        span {
            margin-left: 25px;
            color : #ffedcf;
            font-family: $primary-font-family ;
        }
    }

}
.grid_uniform {
    width: 90% ;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0px;
    flex-direction: row;
    .grid_card{
        max-width: 220px;
        max-height: max-content;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
   
        .product-images { 
            width: inherit;
            height: inherit;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            position: relative;
            cursor: pointer;
       
            .link-product_image {
                position: relative;
                margin: 0px;
                .soldOut {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    width: 100%!important;
                    height: 100%;
                    justify-content: center;
                    background: #00000074;
                    overflow: hidden;
                    color: white;
                    font-family: $primary-font-family;
                    font-size: 18px;
                    object-fit: contain;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
                    img {
                        width: 220px!important;
                        height: 280px!important;
                        object-fit: cover;
                        transition: 1.5s ease-out;
                        max-height: 325px;
                        object-fit: contain;
                }
                #image1 {
                    display: block;
                    opacity: 1;
                   }
                   #image2 {
                      display: none;
                      opacity: 0;
                      
                   }
         }
         .link-product_image:hover #image1 {
            display: none;
            transition: 0.6s ease-in ;
            opacity: 0;
         }
         .link-product_image:hover #image2 {
            display: block;
            opacity: 1;
            transition: 0.6s ease-out ;
            animation: animate 1s ;
            transition : opacity .6s cubic-bezier(.165,.84,.44,1),transform .6s

         }
     
         .product-card_overlay {
            width: 100%!important;
            height: 80px!important;
            position: absolute;
            bottom: 0;
            opacity: 0;
            transition: all .1s ease-in;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            overflow: hidden;
            max-height: 310px;
       
           .card-icons , .card-icons-shop
                {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: $bg-icons;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 14px;
                     #icon{
                        color: white;
                        margin: 5px;
                        font-size: 18px;
                    }
        }
        .card-icons:hover {
            background-color:$bg-icons-hover;
        }
        .card-icons-shop:hover{
            background-color:$bg-icons-hover;
        }
         }

         .product-sale {
            width: 38px;
            height: 38px;
            position: absolute;
            top: 24px;
            left: 15px;
            z-index: 10;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px;
            border-radius: 50%;
            background-color: #ff0000;
            span {
                font-weight: 500;
                font-family: $header-font-family;
                color: white;
                font-size: 12px;
                letter-spacing: 0.5px;
                line-height: 40px;

            }
         }
         
    }
    .product-images:hover .product-card_overlay {
        opacity: 1;        
  
      }
      .product-images:hover .product-card_overlay .card-icons  {
          animation: product-card 0.4s ;
          transition : opacity .6s cubic-bezier(.165,.84,.44,1),transform .6s
  
      }
      .product-images:hover .product-card_overlay .card-icons-shop  {
         
          transition : opacity .6s cubic-bezier(.165,.84,.44,1),transform .6s;
          animation: product-card 0.8s ;
          opacity: 1;
      }
    .product-card_info {
        width: inherit!important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        height: 100px;
        .product_title {
            width: inherit;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 11;
            p {
                font-family: $primary-font-family;
                color: black ;
                font-weight: 500;
                font-size: 13.5px;
                letter-spacing: 0.8px;
                text-transform: uppercase;

            }   

        }
        .product_title:hover p {
            color: #b38c32;
            cursor: pointer;
            transition: 0.3s ease;
        }
        .product_price {
            width: inherit;
            height: 30px;
            display: flex;
            align-items: center;
            position: relative;
            z-index: 11;
            justify-content: center;
            #actPrice {
                font-size: 14px;
                font-family: $primary-font-family;
                color: #b38c32;
                margin: 0px 10px;
            }
            #prevPrice {
                font-size: 14px;
                font-family: $primary-font-family;
                color: black;
                text-decoration: line-through;
            }
        }
        .product-display_images {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 30px!important;
                height: 50px!important;
                margin: 0px 5px;
                object-fit: contain;
                cursor: pointer;
            }
        }
        
    }
}
    }
}

.MuiSlider-rail{
    height: 7px!important;
}
    .MuiSlider-track {
    height: 7px!important;
}
.MuiSlider-thumb{

    height: 15px!important;
    width: 15px!important;
    margin-left: -8px!important;

}

@media screen and ( max-width:769px) {
    .collection-grid {
        width: 100%;
        .collection-filter {
            flex-wrap: wrap;
            justify-content: space-between;
            position: sticky;
            top: 17px;
            z-index: 30;
            width: 92%;
            top: 130px;
            .filter-section {
                flex : 0 1 46%;
                width: 100%;
                background-color: rgb(152, 114, 57);
                display : flex
            }
            .number_results{
                display: none;
            }
            .filter-sort {
                flex : 0 1 46%;
                width: 100%;
                select {
                    width:100%;
                }
                
            }
        }
        .grid_uniform {
            width: 92%;
            .grid_card {
                max-width: 400px;
                .product-images{
                     .link-product_image img  {
                        max-width: 300px!important;
                        max-height: auto!important;
                        height: auto!important;
                        max-height: auto!important;
                     }
                    }
            }
        }
    }
    
}


@media screen and (max-width:489px) {

    .collection-grid {
        .grid_uniform {
         .grid_card {
            .product-images {
                 .link-product_image {
                     img {
                        width: 210px!important;
                     }
                 }
            }
            .product-card_info {
                .product_title {
                    p {
                        font-weight: 600;
                    }
                }
            }
   
         }
        }
    }
    
}
@media screen and (max-width:468px) {

    .collection-grid {
        .grid_uniform {
         .grid_card {
            .product-images {
                 .link-product_image {
                     img {
                        width: 200px!important;
                     }
                 }
                 .product-sale {
                    width: 34px;
                    height: 34px;
                    top: 22px;
                    left: 13px;
                    span  {
                        font-size: 11.8px;
                    }
                }
            }

         }
        }
    }
    
}
@media screen and (max-width:446px) {
    .collection-grid .grid_uniform .grid_card .product-card_info .product-display_images {
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 190px!important;
    }
    .collection-grid {
        .grid_uniform {
         .grid_card {
            .product-images {
                 .link-product_image {
                     img {
                        width: 190px!important;
                     }
                 }
                 .product-sale {
                    width: 32px;
                    height: 32px;
                    top: 20px;
                    left: 11px;
                    span  {
                        font-size: 11.2px;
                    }
                }
            }
         }
        }
    }
    
}

@media screen and (max-width:424px) {
    .collection-grid .grid_uniform .grid_card .product-card_info .product-display_images {
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 180px!important;
    }
    .collection-grid {
        .grid_uniform {
         .grid_card {
            .product-images {
                 .link-product_image {
                     img {
                        width: 180px!important;
                     }
                 }
            }
         }
        }
    }
    
}
@media screen and (max-width:404px) {
    .collection-grid .grid_uniform .grid_card .product-card_info .product-display_images {
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 170px!important;
    }

    .collection-grid {
        .grid_uniform {
         .grid_card {
            .product-images {
                 .link-product_image {
                     img {
                        width: 170px!important;
                     }
                 }
                 .product-sale {
                    width: 30px;
                    height: 30px;
                    top: 18px;
                    left: 10px;
                    span  {
                        font-size: 11.2px;
                    }
                }
            }
         }
        }
    }
    
}
@media screen and (max-width:380px) {
    .collection-grid .grid_uniform .grid_card .product-card_info .product-display_images {
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 160px!important;
    }
    .collection-grid {
        .grid_uniform {
         .grid_card {
            .product-images {
                 .link-product_image {
                     img {
                        width: 160px!important;
                     }
                 }
            }
            .product-card_info .product-display_images img {
                width: 25px!important;
                height: 43px!important;
            }
         }
        }
    }
    
}
@media screen and (max-width:358px) {
    .collection-grid .grid_uniform .grid_card .product-card_info .product-display_images {
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 150px!important;
    }
    .collection-grid {
        .grid_uniform {
         .grid_card {
            .product-images {
                 .link-product_image {
                     img {
                        width: 150px!important;
                     }
                 }
                 .product-sale {
                    width: 28px;
                    height: 28px;
                    top: 21px;
                    left: 7px;
                    span  {
                        font-size: 10.3px;
                    }
                }
            }
         }
        }
    }
    
}
@media screen and (max-width:337px) {
    .collection-grid .grid_uniform .grid_card .product-card_info .product-display_images {
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 140px!important;
    }

    .collection-grid {
        .grid_uniform {
         .grid_card {
            .product-images {
                 .link-product_image {
                     img {
                        width: 140px!important;
                     }
                 }
                 .product-sale {
                    width: 26px;
                    height: 26px;
                    top: 21px;
                    left: 7px;
                    span  {
                        font-size: 9px;
                    }
                }
            }
            .product-card_info .product-display_images img {
                width: 23px!important;
                height: 39px!important;
            }
            .product-card_info {
                .product_title {
                    p {
                        font-size: 11.5px;
                    }
                }
            }
         }
        }
        .collection-filter {
            .filter-section {
            flex : 0 1 45%
        }
        .filter-sort {
            flex : 0 1 45%

        }
    }

    }
    
}
@media screen and (max-width:315px) {
    .collection-grid .grid_uniform .grid_card .product-card_info .product-display_images {
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 130px!important;
    }
    .collection-grid {
        .grid_uniform {
         .grid_card {
            .product-images {
                 .link-product_image {
                     img {
                        width: 130px!important;
                     }
                 }
            }
         }
        }
        .collection-filter {
            .filter-section {
            flex : 0 1 43%
        }
        .filter-sort {
            flex : 0 1 43%

        }
    }
    }
    
}

a{
    text-decoration: none;
    margin: 0px;
    padding: 0px;
}