@import '../../variables.scss';

.login {
    width: 100%;
    height: 100%;
}
.login_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .login_container{
        font-family:$primary-font-family;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 22px;
        width: 370px;
        padding: 10px 0px;
        max-width: 90%;
        margin: 80px 0px;
        span {
            color:$colorTextBody ;
            font-family: $primary-font-family;
            font-size: 26px;
            letter-spacing: 0.025em;
            font-weight: 500;
            padding: 30px 0px;
            margin: 0px 0px;
        }
        .reject{
            font-size: 15px;
            font-weight: 500;
            padding: 0px;
            cursor: pointer;
        }
        h3 {
            margin: 0px;
            font-weight: 400;
            color: rgb(108, 85, 15);
            text-align: center;
            font-family: $primary-font-family;

        }
        p {
            text-align: center;
            font-size: 13px;
            margin: 0px auto;
            font-family: $primary-font-family;
            max-width: 80%;
            color: rgb(65, 50, 6);
        }
        .input_items_error {
            width: 100%;
            border: 1px solid #d02e2e;
            background-color: #fff6f6;
            color: #d02e2e;
            span {
                font-size: 12px;
                padding: 10px 10px ;
                display: flex;
                align-items: center;
                height: 100%;
                gap: 15px;
            }
        }
        .input_items {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 10px;
            width: 100%;
            .mdp{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px;
                margin: 0px;
                .mdp_reset {
                    background-color: transparent;
                    font-size: .72em;
                    margin: 0px;
                    padding: 0px;
                    color: rgb(63, 43, 6);;
                    line-height: 10px;
                    font-weight: normal;
                    cursor: pointer;
                }
            }
            label {
                font-family: $primary-font-family;
                font-weight: 400;
                font-size: .7em;
                letter-spacing: .3em;
                text-transform: uppercase;
                color: rgb(63, 43, 6);;
                
            }
            input {
                height:27px;
                border: 1px solid $border;
                font-family: $primary-font-family;
                padding: 2px 10px;
                caret-color: $color-references;
                color: $colorTextBody;
                font-size: 14px!important;
                font-weight: 400!important;
                letter-spacing: 0.025em;

            }
         
         
        }
        .button {
            width: 100%;
            height: 45px;
            background-color: rgba(115, 85, 29, 0.93);
            color: white;
            font-size: 13.5px;
            letter-spacing: 0.25rem;
            font-weight: 600;
            cursor: pointer;
            text-transform: uppercase;
            border: 0px;
            font-family: $primary-font-family;
        }
        .button:hover {
 
            box-shadow: inset  400px 0 0 0 rgba(115, 85, 29, 0.231);;
            transition:  box-shadow .6s ease 0s;
            transition-delay: 0.25s;
            background-color: rgba(115, 85, 29, 0.756);
        }
    }
}

@media screen and (max-width: 769px) {

    .login_wrapper .login_container span {
        font-size: 22.2px;
}
.login_wrapper .login_container h3{
    font-size: 20.2px;
}
.login_wrapper .login_container p {
    font-size: 12px;
}
.login_wrapper .login_container .reject {
    font-size: 14px;
}
    
}