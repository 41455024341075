@import '../variables.scss';

.site-header__scroll {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0px;
    position: fixed;
    top: 0;
    background-color: white;
    left: 0;
  //  transition: 0.3s ease-in ;
    z-index: 100;
    .page-width {
        width: 93%;
        display: flex;
        align-items: center;
    max-width: 1500px;
        
        .header-layout-search {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #000;
            font-size: 26px;
            width: 33%;
            cursor: pointer;
            #hamburger{
                display: none;
                height: 30px;
                width: 30px;
                vertical-align:top;

            }
            #search {
                display: flex;
            }
        }
        .header-layout-logo {
            width: 33%;
            display: flex;
            justify-content: center;
            max-height: 120px;
            cursor: pointer;
            img {
                object-fit: contain;
                margin-left: -30px;
                object-fit: contain;
                height: inherit;
                height: 90px;

            }

        }
        .header-layout-shop {
            width: 33%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .icons {
                display: grid;
                gap: 33px;
                grid-template-columns: auto auto ;
                margin-right: 4px;
                #icon1 , #icon , #icon2 {
                    font-size: 24px;
                     color: #000;
                     cursor: pointer;
                }
                .basketNumbers {
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 12px;
                    background-color: rgb(140, 94, 30);
                    color: white;
                    padding: 4px 10px;
                    border-radius: 50%;
                    margin-top: -15px;
                    margin-right: -10px;
                    font-family: $primary-font-family;
                }
                #icon {
                    display: none;
                }

            }
        }
    }
    .search-section {
        width: 100%;
        height: 100vh;
        background-color: #e6e6e6b0;
        position: fixed;
        top: 0;
        z-index: 100;
    }  
     .search_results {
        width: 100%;
            height: max-content;
            background-color:rgb(251, 251, 251);
            z-index: 100;
            position: fixed;
            left: 0;
             display: flex;
             align-items: center;
             justify-content: center;
             flex-direction: column;
             .grid-product {
                margin-top:10px;
                width: 90%;
                height: inherit;
                display: grid;
                gap: 20px;
                grid-template-columns: auto auto auto auto;
                .grid-item {
                    height: 280px;
                    cursor: pointer;
                    .grid__image-ratio {
                        height: 250px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        img {
                            object-fit: contain;
                            height: inherit;
                            width: inherit;
                        }
                    }
                    .grid-product_title {
                        width: 100%;
                        height: 30px ;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        font-family: $primary-font-family;
                        p {
                            font-size: 14px;
                            margin: 0px;
                            margin-top: 5px;
                            color: #000;
                        }
                        span {
                            font-size: 13.5px;
                            color: $header-background-color;

                        }
                      
                    }
                }
                .grid-item:hover .grid-product_title p {
                    color: $header-background-color;
                }
              
         }
         .more_results {
            display:flex;
            align-items: center;
            justify-content:center;
            width: 90%;
            padding: 15px;
            text-decoration: none;

            button {
                padding: 10px;
                border: 1px solid $header-background-color;
                background-color: $header-background-color;
                color: white;
                font-family: $primary-font-family;
                font-size: 14px;
                letter-spacing: 1px;
                cursor: pointer;
            }
            button:hover {
                background-color: $bg-icons-hover;
            }
            
         }

    }
        .site-header_search {
            width: 100%;
            height: 200px!important;
            background-color: rgb(255, 255, 255);
           z-index: 100;
           display: flex;
           justify-content: center;
           align-items: center;
           position: fixed;
           width: 100vw;
           top: 0;
           box-shadow: 0 15px 45px #0000001a;
           border-top: 1px solid  #00000033;
           transition: transform 0.5s ease-out;
            animation: open-search-section 0.5s;


           .page-width {
            width: 100%;
            height: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;  
            .search-form {
                width: 85%;
                height: 100%;
                display: flex;
             button {
                background-color: transparent;
                border: none;
                width: 15%;
                #search-icon {
                    font-size: 29px;
                    cursor: pointer;
                    color: #6b6b6b;
                }
            }  
            input {
                width: 85%;
                height: 100%;
                font-size: 20px;
                border: 0px;
                padding: 0px 10px;
                color: #323232;
                ;
                font-family: $primary-font-family;

            }
            input::placeholder {
                font-family: $primary-font-family;
                font-size: 15px;
                opacity: 0.8;
                letter-spacing: 0.025em;
            }

                
            }
            .close-button {
                width: 15%;
                display: flex;
                justify-content: center;
                align-items: center ;
                #close {
                    font-size: 29px;
                    cursor: pointer;
                    color: #323232;



                }
            }


        }
    }
    .navigation-section {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .navigation-items {
            display: grid;
            grid-template-columns: auto auto auto auto auto ;
            gap: 30px;
            font-family: $header-font-family;
            letter-spacing: 1px;
            font-weight: 410;
            position: relative;
          
            .nav{
                display: inline-block;
                color: #000;
                text-decoration: none;
                cursor: pointer;
                height: 30px;
                position: relative;
                font-family: $primary-font-family;
                font-weight: 700;
                font-size: 16px;
            }
     
     
            .nav::after {
                content: '';
                display: block;
                width: 0;
                height: 2.4px;
                background:#edd6a0;
                transition: width .35s;
            }
            .list:hover .nav::after {
                width: 100%;
                cursor: pointer;

            }
            .list:hover .nav {
                color: #d0b678;

            }
            .list {
                position: relative;
                display: block;
                #sale {
                    padding: 4px;
                    position: absolute;
                    background-color: #eb4d55;
                    top: -30px;
                    left: 25px;
                    font-size: 10px;
                    color: white;
                    font-weight: 500;
                }
            }
            .nav-items-new , .nav-items-category , .nav-items-collections  , .nav-items-maison {
                position: absolute;
                margin: 0;
                padding: 15px 14px 5px 0px;
                display: grid;
                gap: 10px;
                margin-left: -14px;
                visibility: visible;
                z-index: 1000;
                background-color: white;
               animation: list-drop-down 0.2s linear forwards;
               border: 1px solid #f1f1f1;
               box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 5%);
               width: 250px;
                li {
                    margin-left: 15px;
                    font-weight: 390;
                    font-size: 18px;
                    color: black;
                }
                a {
                    text-decoration: none;
                }
                li:hover {
                color: #c7a860;
                cursor: pointer;
                }
            }
       
           
            
        }
    }

}

.all_currencies {
    content: "";
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e6e69c ; 
    z-index: 200;
    
    .container_currencies {
        width: 35%;
        height: 90%;
        background-color: white;
        z-index: 25;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 3s ease;
        animation: overlay-open 1s forwards ;
        #close_bar {
            width: 93%;
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            #close {
                font-size: 19px;
                color: $header-text-color;
                cursor: pointer;
            }
        }
        .table_currencies {
            width: 93%;
            height: max-content;
            display: grid;
            gap: 20px;
            margin-top: 20px;
            .currency-option-button {
                display: flex;
                width: 170px;
                height: 40px;
                margin: 5px auto;
                padding: 5px;
                align-items: center!important;
                #flag {
                    width: 50px!important;
                    height: 30px!important;
                }
                img {
                    object-fit: cover;
                }
                span {
                    margin:0px ;
                    padding: 0px;
                    font-family:$currency-font-family;
                    cursor: pointer;
                    font-size: 23px;
                    line-height: 24px;
                    margin-left: 35px;
                    color: #1a1113ca;
                }
            }
         
            button {
                background: none;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }
        #info_message {
            width: 93%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $currency-font-family;
            color: #1a1113e1;
            text-align: center;
        }

    }

}
.nav_bar {
    width: inherit;
    position: relative;
    .top {
        width: 100%;
        height: 50px;
        background-color: $header-background-color;
        font-family: $header-font-family;
        color: $header-text-color;
        .conatainer {
            width: 90%;
            height: inherit;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            font-size: $header-font-size;
            margin-top: -2px;
             max-width: 1500px;

            p {
                font-family: "Open Sans", sans-serif                ;
                color: $header-text-color;
                font-weight: 400;
                cursor:default;
                text-align: center;
                color: #fff6e0;
                font-family: $primary-font-family;
                font-weight: 800;
                display: block;
                text-transform: uppercase;
                letter-spacing: .2em;
                font-size: .75em;
                text-align: center;
                span  {
                    text-transform: none;
                    color: white;
                }
            }
            .social {
                display: flex;
                justify-content: space-between;
                align-items: center;
                a{
                    text-decoration: none;
                    color: white;

                }
                #icon {
                    margin-left: 16px;
                    cursor: pointer;
                    font-size: 13.5px;
                    color: #fff6e0;

                }
            }
            .carousel {
                width: 70%;
                margin-left: auto;
                margin-right: auto;
                margin: 0px;
                
                p {
                    margin: 0px;
                    margin-top: 5px;
                    height: 100%;
                    font-size: 11.5px;
                    letter-spacing: 0.15em;
                    font-weight: 600;
                    color: rgb(68, 43, 7);
                    margin: 0px;
                    span {
                        font-weight: 500;
                        color: #fff6e0;
                        
                    }
                }
            }
            .carousel .slider-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
        }
    }
    .bottom {
        width: 100%;
        height: 35px;
        background-color: white;
        border-bottom: 1px solid rgb(234, 234, 234);
        .container {
            width: 90%;
            height: 100%;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: $header-font-family;
            max-width: 1500px;

            .container_left {
                width: 100%;
                max-width: 700px;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                a {
                    text-decoration: none;
                    letter-spacing: 1.2px;
                    color: $header-text-color;
                    font-size: 14px;
                    font-weight: 400;

                }
        }
        .container_right {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .flags {
                width: max-content;
                height: 100%;
                display: flex;
                align-items: center;
            .container_flag {
                width: max-content;
                height: max-content;
                cursor: pointer;
                #flag {
                        width: 1.7em!important;
                        height: 1.7em!important;
                        margin-right: 0.5em;
                }
                #nameFlag {
                    font-family: $header-font-family!important;
                    font-size: 17px!important;
                    font-weight: 100!important;
                }
        }

        }
    
    }

        }
     
    }
    .site-header {
        width: 100%;
        height: 210px;
                display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 7px 0px;
        background-color: white;
 
        .page-width {
            width: 93%;
            display: flex;
            align-items: center;
    max-width: 1500px;

            .header-layout-search {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #000;
                font-size: 26px;
                width: 33%;
                cursor: pointer;
                #hamburger{
                    display: none;
                    height: 30px;
                    width: 30px;
                    vertical-align:top;

                }
                img {
                   
                }
                #search {
                    display: flex;
                }
            }
            .header-layout-logo {
                width: 33%;
                display: flex;
                justify-content: center;
                max-height: 145px;
                img {
                    object-fit: contain;
                    margin-left: -30px;
                    object-fit: contain;
                    height: inherit;
                }

            }
            .header-layout-shop {
                width: 33%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .icons {
                    display: grid;
                    gap: 33px;
                    grid-template-columns: auto auto ;
                    margin-right: 4px;
                    #icon1 , #icon , #icon2 {
                        font-size: 24px;
                         color: #000;
                         cursor: pointer;
                    }
                    .basketNumbers {
                        position: absolute;
                        right: 0;
                        top: 0;
                        font-size: 12px;
                        background-color: rgb(140, 94, 30);
                        color: white;
                        padding: 4px 10px;
                        border-radius: 50%;
                        margin-top: -15px;
                        margin-right: -10px;
                        font-family: $primary-font-family;
                    }
                    #icon {
                        display: none;
                    }

                }
            }
        }
        .search-section {
            width: 100%;
            height: 100vh;
            background-color: #e6e6e6b0;
            position: fixed;
            top: 0;
            z-index: 100;
        }
        .search_results {
            width: 100%;
            height: max-content;
            background-color:rgb(251, 251, 251);
            z-index: 100;
            position: fixed;
            left: 0;
             display: flex;
             align-items: center;
             justify-content: center;
             flex-direction: column;
             .grid-product {
                margin-top:10px;
                width: 90%;
                display: grid;
                gap: 20px;
                grid-template-columns: auto auto auto auto;
                max-width: 1500px;
                .grid-item {
                    height: 280px;
                    cursor: pointer;
                    .grid__image-ratio {
                        height: 250px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        img {
                            object-fit: contain;
                            height: inherit;
                            width: inherit;
                        }
                    }
                    .grid-product_title {
                        width: 100%;
                        height: 30px ;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        font-family: $primary-font-family;
                        p {
                            font-size: 14px;
                            margin: 0px;
                            margin-top: 5px;
                            color: #000;

                        }
                        span {
                            font-size: 13.5px;
                            color: $header-background-color;
                        }
                      
                   
                    }
                }
                .grid-item:hover .grid-product_title p {
                    color: #d0b678;
                }
             }
             .more_results {
                display:flex;
                align-items: center;
                justify-content:center;
                width: 90%;
                padding: 15px;
                text-decoration: none;
                button {
                    padding: 10px;
                    border: 1px solid $header-background-color;
                    background-color: $header-background-color;
                    color: white;
                    font-family: $primary-font-family;
                    font-size: 14px;
                    letter-spacing: 1px;
                    cursor: pointer;
                }
                button:hover {
                    background-color: $bg-icons-hover;
                }
                
             }

        }
            .site-header_search {
                width: 100%;
                height: 200px!important;
                background-color: rgb(255, 255, 255);
               z-index: 100;
               display: flex;
               justify-content: center;
               align-items: center;
               position: fixed;
               top: 0;
               box-shadow: 0 15px 45px #0000001a;
               border-top: 1px solid  #00000033;
               animation: open-search-section 0.5s ;
           transition: transform 0.5s ease-out;

   

               .page-width {
                width: 100%;
                height: 90%;
                display: flex;
                justify-content: space-between;
                align-items: center;  
                .search-form {
                    width: 85%;
                    height: 100%;
                    display: flex;
                 button {
                    background-color: transparent;
                    border: none;
                    width: 15%;
                    #search-icon {
                        font-size: 29px;
                        cursor: pointer;
                        color: #6b6b6b;
                    }
                }  
                input {
                    width: 85%;
                    height: 100%;
                    font-size: 20px;
                    border: 0px;
                    padding: 0px 10px;
                    color: #323232;
                    ;
                    font-family: $primary-font-family;

                }
                input::placeholder {
                    font-family: $primary-font-family;
                    font-size: 15px;
                    opacity: 0.8;
                    letter-spacing: 0.025em;
                }

                    
                }
                .close-button {
                    width: 15%;
                    display: flex;
                    justify-content: center;
                    align-items: center ;
                    #close {
                        font-size: 29px;
                        cursor: pointer;
                        color: #323232;



                    }
                }


            }
        }
        .navigation-section {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .navigation-items {
                display: grid;
                grid-template-columns: auto auto auto auto auto ;
                gap: 30px;
                font-family: $header-font-family;
                letter-spacing: 1px;
                font-weight: 410;
                position: relative;
              
                .nav{
                    display: inline-block;
                    color: #000;
                    text-decoration: none;
                    cursor: pointer;
                    height: 30px;
                    position: relative;
                    font-family: $primary-font-family;
                    font-weight: 700;
                    font-size: 16px;
                }
         
         
                .nav::after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 2.4px;
                    background:#edd6a0;
                    transition: width .35s;
                }
                .list:hover .nav::after {
                    width: 100%;
                    cursor: pointer;

                }
                .list:hover .nav {
                    color: #d0b678;

                }
                .list {
                    position: relative;
                    display: block;
                    #sale {
                        padding: 4px;
                        position: absolute;
                        background-color: #eb4d55;
                        top: -30px;
                        left: 25px;
                        font-size: 10px;
                        color: white;
                        font-weight: 500;
                    }
                }
                .nav-items-new , .nav-items-category , .nav-items-collections  , .nav-items-maison {
                    position: absolute;
                    margin: 0;
                    padding: 15px 14px 5px 0px;
                    display: grid;
                    gap: 10px;
                    margin-left: -14px;
                    visibility: visible;
                    z-index: 1000;
                    background-color: white;
                   animation: list-drop-down 0.2s linear forwards;
                   border: 1px solid #f1f1f1;
                   box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 5%);
                   width: 250px;
                    li {
                        margin-left: 15px;
                        font-weight: 390;
                        color: black;
                        font-size: 18px;
                    }
                    a {
                        text-decoration: none;
                    }
                    li:hover {
                    color: #c7a860;
                    cursor: pointer;
                    }
                }
           
               
                
            }
        }

    }
}

li {
    list-style: none;
}
ul {
    list-style-type: none;
   }
   input:focus, textarea:focus, select:focus{
    outline: none;
}

@keyframes overlay-open {
    from {
      opacity: 0;
      transform: translateY(30px);

    }
  
    to {
      opacity: 1; 
      transform: translateY(0);
    } 
}

@keyframes list-drop-down {
    from {
      opacity: 0;
      transform: translateY(-10px);

    }
  
    to {
      opacity: 1; 
      transform: translateY(0);
    } 
}


@keyframes open-search-section {

    from {
        transform: scale(0);
        transform-origin: top;
    }
    to {
        transform: scaleY(1);
    }
    
}

@media screen and (max-width: 769px) {
    .site-header__scroll {
        height: 100px!important;
        box-shadow:   0 -9px 23px -1px grey;
        .navigation-section {
            display: none;
        }
        .page-width {
            width: 96%;
            .header-layout-search {
                #search {
                    display: none;
                }
                #hamburger {
                    display: block;
                    font-size: 16px;
                }
                img {
                    object-fit: contain;
                    height: inherit;
                }
            
            }
            .header-layout-shop {
                .icons {
                    gap: 14px;
                    #icon {
                        display: block;
                        font-size: 30px;
                    }
                    #icon1 {
                        display: none;
                    }
                }
            }
            .header-layout-logo {
                img {
                    height: 84px;
                }
            }
        }
    }
    
}

@media screen and (max-width: 769px) {

    .nav_bar .site-header .search_results .grid-product {

        grid-template-columns : auto auto auto ;

    }
    .site-header__scroll {
        .search_results {
            .grid-product {
                grid-template-columns : auto auto ;
                .grid-item {
                    .grid__image-ratio {
                        height: 250px;
                    }
                }
            }
        }
    }
    .nav_bar  {
        height: max-content;
        .bottom {
            display: none;
        }
        .site-header {
            height: 100px!important;
            .navigation-section {
                display: none;
            }
            .page-width {
                width: 96%;
                .header-layout-search {
                    #search {
                        display: none;
                    }
                    #hamburger {
                        display: block;
                        font-size: 16px;
                    }
                    img {
                        object-fit: contain;
                        height: inherit;
                    }
                
                }
                .header-layout-shop {
                    .icons {
                        gap: 14px;
                        #icon {
                            display: block;
                            font-size: 30px;
                        }
                        #icon1 {
                            display: none;
                        }
                    }
                }
                .header-layout-logo {
                    img {
                        height: 84px;
                    }
                }
            }
        }
        .top {
            height: 60px;
            .conatainer {
                justify-content: center;
                width: 95%;
                #phoneNumber { 
                    display: none;

                }
                .social {
                    display: none;
                }
                p {
                    text-align: center;
                    line-height: 15px;
                    font-size: 11px;
                }
                .carousel {
                    width: 100%;
                    height: 100%;
                }
              
            }
        }
    }
    .all_currencies {
      .container_currencies  {
        width: auto;
      }
    }
}

@media screen and (max-width:600px) {
    .nav_bar .site-header .search_results .grid-product {

        grid-template-columns : auto auto  ;

    }
    
}


@media screen and (min-height:670px) and (max-width:440px) {
    .all_currencies {
        .container_currencies  {
          height: fit-content;
          width: 370px;

        }
    }
}
@media screen and (min-width:700px) and (min-height:800px){
    .all_currencies {
        .container_currencies  {
          height: fit-content!important;
          width: 450px!important;

        }
    }
    
}
@media screen and (max-width:1200px) and (min-width:1000px) {

    .nav_bar .bottom .container .container_left {
        width: 70%;
    }
    .nav_bar .bottom .container .container_right {
        width: 30%;
    }
    
}
@media screen and (max-width:1000px) and (min-width:769px) {

    .nav_bar .bottom .container .container_left {
        width: 80%;
    }
    .nav_bar .bottom .container .container_right {
        width: 20%;
    }
    
}

@media  screen and (max-width:420px ){

    .nav_bar .site-header .page-width .header-layout-logo img {
        height: 84px!important;
    }
    
}
@media  screen and (max-width:420px ){

    .site-header__scroll .page-width .header-layout-logo img {
        height: 84px!important;
    }
    
}
@media  screen and (min-width:420px ) and (max-width:460px){

    .nav_bar .site-header .page-width .header-layout-logo img {
        height: 92px!important;
    }
    
}
@media  screen and (min-width:420px ) and (max-width:460px){

    .site-header__scroll .page-width .header-layout-logo img {
        height: 92px!important;
    }
    
}
@keyframes section-scroll {

    from {
        height: 0;
        opacity: 0;

    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
    
}

@media screen and (max-width: 540px) {
    .nav_bar .site-header .site-header_search {
        height:150px!important
    }
    .site-header__scroll .site-header_search {
        height:150px!important
        
    }
    
}

@media screen  and (max-height:700px) and (max-width:540px) {
    .nav_bar {
        .site-header , .site-header__scroll  {
            .site-header_search {
              height: 115px!important;
         }
         .search_results {
            .grid-product {
                width: 95%;
                gap: 9px;
                .grid-item {
                    height: 230px;
                       .grid__image-ratio {
                    height: 200px;
                }
                }
            }
              .more_results {
            padding: 0px;
            padding-bottom: 10px;
            margin-top: 10px;
            button {
                padding: 5px;
            }

        }
         }
       }
    }

}

@media screen  and (max-height:650px) and (max-height:540px) {
    .nav_bar {
        .site-header , .site-header__scroll  {
            .site-header_search  {
              height: 115px!important;
         }
         .search_results {
            .grid-product {
                width: 95%;
                gap: 9px;
                .grid-item {
                    height: 230px;
                       .grid__image-ratio {
                    height: 200px;
                }
                }
            }
              .more_results {
            padding: 0px;
            padding-bottom: 10px;
            margin-top: 10px;
            button {
                padding: 5px;
            }

        }
         }
       }
    }

}


@media screen  and (max-width: 600px) and (max-height:850px) {
    .nav_bar .site-header .search_results .grid-product .grid-item {
        height: 240px!important;
        .grid__image-ratio {

        height: 220px!important;

    }}
    .site-header__scroll .search_results .grid-product .grid-item {

        height: 240px!important;
        .grid__image-ratio {

        height: 220px!important;
        }
    }
}

@media screen  and (max-width: 450px) and (max-height:800px) {
    .nav_bar .site-header .search_results .grid-product .grid-item {
        height: 240px!important;
        .grid__image-ratio {

        height: 220px!important;

    }}
    .site-header__scroll .search_results .grid-product .grid-item {
        height: 240px!important;
        .grid__image-ratio {

        height: 220px!important;

    }
    }
}

@media screen  and (max-height: 780px) and (max-width : 540px) {
    .nav_bar .site-header .search_results .grid-product .grid-item {
        height: 220px!important;
        .grid__image-ratio {

        height: 200px!important;

    }}
    .site-header__scroll .search_results .grid-product .grid-item {
        height: 220px!important;
        .grid__image-ratio {

        height: 200px!important;

    }
    }
}

@media screen  and (max-height: 730px) and (max-width : 540px) {
    .nav_bar .site-header .search_results .grid-product .grid-item {
        height: 210px!important;
        .grid__image-ratio {

        height: 180px!important;

    }}
    .site-header__scroll .search_results .grid-product .grid-item {
        height: 210px!important;
        .grid__image-ratio {

        height:180px!important;

    }
    }
}
@media screen  and (max-height: 630px) and (max-width : 540px) {
    .nav_bar .site-header .search_results .grid-product .grid-item {
        height: 190px!important;
        .grid__image-ratio {

        height: 160px!important;

    }}
    .site-header__scroll .search_results .grid-product .grid-item {
        height: 190px!important;
        .grid__image-ratio {

        height:160px!important;

    }
    }
}

@media screen  and (max-height: 580px) and (max-width : 540px) {
    .nav_bar .site-header .search_results .grid-product .grid-item {
        height: 170px!important;
        .grid__image-ratio {
        height: 140px!important;

    }}
    .nav_bar .site-header .search_results .grid-product .grid-item .grid-product_title p {
        font-size: 12px;
    }
    .site-header__scroll .search_results .grid-product .grid-item .grid-product_title p {
        font-size: 12px;

    }
    .site-header__scroll .search_results .grid-product .grid-item {
        height: 170px!important;
        .grid__image-ratio {

        height:140px!important;

    }
    }
}
@media screen  and (max-height: 540px) and (max-width : 540px) {
    .nav_bar .site-header .search_results .grid-product .grid-item {
        height: 160px!important;
        .grid__image-ratio {
        height: 130px!important;

    }}
    .site-header__scroll .search_results .grid-product .grid-item {
        height: 160px!important;
        .grid__image-ratio {

        height:130px!important;

    }
    }
}

@media screen  and (max-height: 500px) and (max-width : 540px) {
    .nav_bar .site-header .search_results .grid-product .grid-item {
        height: 150px!important;
        .grid__image-ratio {
        height: 120px!important;

    }}
    .site-header__scroll .search_results .grid-product .grid-item {
        height: 150px!important;
        .grid__image-ratio {

        height:120px!important;

    }
    
    }
    .nav_bar .site-header .site-header_search, .nav_bar .site-header__scroll .site-header_search {
        height: 80px!important;
    }
    .nav_bar .site-header .search_results .grid-product .grid-item .grid-product_title p {
        font-size: 10px;
    }
    .site-header__scroll .search_results .grid-product .grid-item .grid-product_title p {
        font-size: 10px;

    }
}

a{
    text-decoration: none!important;
}