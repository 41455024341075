
@import '../../variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');
.shpping_section{
    width: 100%;
    height: auto;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

    .expidition {
        font-size: 17px;
        font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-weight: 380;
        color: rgb(51,51,51);
        letter-spacing: 0.5px;
    }
    .shpping_infos {
        display: flex;
        flex-direction: column;
        gap: 13px;
        border-radius: 5px ;
        border: 1Px solid rgba(85, 85, 85, 0.22) !important;
        padding: 13px 20px;
        .divider{
            height: 0.5px;
            width: 90%;
            margin: auto;
            background-color:rgba(85, 85, 85, 0.22)  ;
        }
        .shpping_infos_items {
            display: flex;
            align-items: center;
            gap: 25px;
            cursor: pointer;

            span {
                color: rgb(117, 116, 114);
                font-size: 12.5px;
                font-weight: 400;
                width: 70px;             
            }
            input {
                background-color: white;
                -webkit-appearance: none;
                height: 18px;
                margin-block-start: .14285714285714285em;
                margin-top: .14285714285714285em;
                width: 18px;
                border-radius: 50%;
                background-clip: padding-box;
                border-style: solid;
                border-width:1px;
                transition: 0.2s ease;
                cursor: pointer;
            }
            input:checked {
                border: .42857142857142855em rgb(113, 82, 15) solid;
                transition: 0.2s ease;

            }
            .shipping_values {
                width: 100%;
                display: flex;
                justify-content: space-between;
                span {
                color:#545454;
                font-weight: 400;
                font-size: 14px;
                width: max-content;    
                }
                .edit {
                    color:rgb(83, 55, 0); 
                    font-size: 12px;
                    cursor: pointer;

                }
                .value{
                    color:rgb(83, 55, 0); 
                    font-weight: 600;
                    font-family: 'Lato', 'Helvet';
                    font-size: 13px;
                }
            }
        }
        
    }
}


@media screen and (max-width:769px) {
    .shpping_section .shpping_infos .shpping_infos_items .shipping_values span{
        width: 70%!important;
    }
    .shpping_section .shpping_infos .shpping_infos_items .shipping_values .value {
        width: max-content!important;
    }
    .shpping_section .shpping_infos .shpping_infos_items .shipping_values .edit{
        width: max-content!important;
    }
    
}