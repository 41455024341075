@import '../variables.scss';
.categories {
    width: 100%;
    height:  100%;
    .navbar{
        width: 100%;
        height: max-content ;
    }
    .categoryName_block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        margin: 10px 0px;
        .title {
            font-size: 26px;
            font-family: $primary-font-family;
            color: $colorTextBody;
            letter-spacing: 2px;
            text-transform: uppercase;
            padding:20px 30px;
      
        }
    }
    .pagination {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 40px;
        display: flex;
        justify-content: center;
        gap: 20px;
}

 
}

@media screen and (max-width: 450px) {
    .categories{
        height: 80px;
        .categoryName_block .title  {
            font-size: 22px;
            font-weight: 800;
        }
    }
}
