@import '../../../variables.scss';

.comamndes_historque {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .comamndes_container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-family: $primary-font-family;
        .order_header {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .num_commande {
                font-size: 17px;
                font-weight: 700;
                color: rgb(0, 0, 0);
                margin: 0px;
            }
            .sub_title {
                font-size: 11.5px;
                color: #6e6d7a  ;
                font-weight: 500;
                margin: 0px;
            }
        }
        .products {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                width: 40px;
                height: 40px;
            }
            .title_product {
                font-size: 13px;
                color: black;
            }

        }
     
    }
}