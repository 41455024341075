@import '../../variables.scss';

.cvg_page {
width: 100%;

.cvg_wrapper {
    height: 100%;
    display: flex;
    padding: 15px;
    justify-content: center;
    margin-top: 100px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    min-height: 700px;
    padding: 10px 0px;
    .cvg_container {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 15px 30px;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        font-family: $primary-font-family;
        margin: 35px 0px;
        .title {
            font-family: $primary-font-family;
            color: rgb(49, 31, 5);;
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0px 0px 25px 0px;
        }
        .cvg_left  {
            display: flex;
            flex-direction: column;
            gap: 20px;
           width: 90%;
           max-width: 1000px;
           .cvg_section{
            width: 100%;
            max-width: 950px;
            margin-left: auto;
            margin-right: auto;
            h5 {
                font-size: 15px;
                color: rgb(105, 73, 28);
            }
            span {
                font-size: 13.5px;
                color: rgb(49, 31, 5);
                line-height: 22px;
            }
            a {
                text-decoration: none;
                font-weight: 800;
                color:rgb(49, 31, 5);
            }

           }
     

        }

    }
}


}
@media screen and (max-width:450px) {
    .cvg_guide .cvg_wrapper .cvg_container .cvg_left .cvg_title_section h4 {
        font-size: 20px;
    }
    
}
@media screen and (max-width:500px) {
    .dilevery .cvg_wrapper .cvg_container .cvg_left{
        width: 100%;
    }
    
}