
@import '../variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');
.order{

    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
            font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    height: max-content;
    min-height: 100%;
    .order_stepper{
        width: 57%;
        height: 100%;
        .stepper_container{
            padding-top: 4.1rem;
            padding-left: 6.3vw;
            padding-right: 5.4vw;
            padding-bottom: 4.1rem;
                .logo{
                            font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                    font-size: 26px;
                    letter-spacing: 1px;
                    margin: 0px;
                    color: rgb(38, 38, 38);
                    cursor: pointer;
                }
                .buttons {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: 30px 0px;
                    .back {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: rgba(115, 85, 29, 0.93);
                        font-weight: 400;
                        letter-spacing: 0.3px;
                        cursor: pointer;
                    }
                    .icon {
                        margin-top: 5px;
                    }
                    .next {
                        width: 220px;
                        height: 55px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(115, 85, 29, 0.93);
                        border-radius: 5px;
                        color: white;
                        cursor: pointer;
                        transition: 0.4s ease;
                        .loader {
                            color: white ;
                            animation: load 0.7s  linear infinite;
                            font-size: 22px;
                        }
                    }
                    .submit {
                        width: 220px;
                        height: 55px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(115, 85, 29, 0.93);
                        border-radius: 5px;
                        color: white;
                        cursor: pointer;
                        transition: 0.4s ease;
                        border: 0px;
                        .loader {
                            color: white ;
                            animation: load 0.7s  linear infinite;
                            font-size: 22px;
                        }
                    }
                    .next:hover {
                        background-color: rgba(81, 58, 17, 0.83);

                    }
                }
                .inforamtion_section {
                    width: 100%;
                    height: auto;
                    .express_checkout {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        .form_infos {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 30px;
                            .form_infos_adress {
                                display: flex;
                                flex-direction: column;
                                font-family: 'Lato', sans-serif;
                                gap: 17px;
                                h2 {
                                    font-family: 'Lato', sans-serif;
                                    color: rgb(28, 18, 0);
                                    font-size: 17.5px;
                                    font-weight: 400;
                                    letter-spacing: 0.3px;
                                }
                                .separator{
                                    display: flex;
                                    width: 100%;
                                    gap: 20px;
                                }
                                .input_items{
                                    width: calc(100% - 10px) ;
                                    padding: 0px 5px;
                                    height: 48px;
                                    border: 2px solid rgb(217,217,217) ;
                                    border-radius: 4px;
                                    position: relative;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    gap: 3px;
                                    overflow: hidden;
                                    select {
                                        appearance: none;
                                    }
                                    .arrow_section {
                                        position: absolute;
                                        right: 0;
                                        top: 0;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        margin-top: 5px;
                                        .arrow{
                                            padding: 0px 10px;
                                        }
                                    }
                                
                                    .select {
                                        width: 98%;
                                        border: 0px;
                                        color: rgb(50, 34, 4);
                                        letter-spacing: 0.3px;
                                        font-family: 'Lato', sans-serif;
                                        font-size: 15px;
                                        position: absolute;
                                        height: 95%;
                                        top: 10px;
                                    }
                                    label {
                                        top: 5px;
                                        margin-left: 6px;
                                        font-size: 12px;
                                        letter-spacing: 0.3px;
                                        position: absolute;
                                        top: 6px;
                                        left: 0;
                                        z-index: 10;
                                    }
                                   
                                }
                                .focused {

                                    border: 2px solid rgb(82, 56, 9);
                                }
                              

                            }
                            .form_infos_details {
                                display: flex;
                                flex-direction: column;
                                font-family: 'Lato', sans-serif;
                                .title_wrapper {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    width: 100%;
                                    gap: 10px;
                                    span  {
                                        font-size: 14.6px;
                                         color: rgb(102, 102, 102);

                                    }
                                }
                                h2 {
                                    font-family: 'Lato', sans-serif;
                                    color: rgb(28, 18, 0);
                                    font-size: 17.5px;
                                    font-weight: 400;
                                    letter-spacing: 0.3px;
                                }
                                .email{
                                    font-size: 14.8px;
                                    font-weight: 400;
                                    color: rgb(80, 80, 80);
                                    letter-spacing: 0.3px;
                                    margin-bottom: 7px;

                                }
                                .disconnect {
                                    cursor: pointer;
                                    color: rgb(61, 44, 5);
                                    font-size: 14.8px;
                                    margin-bottom: 7px;
                                }
                                .disconnect:hover {
                                    color: rgb(128, 100, 34);

                                }
                                .newsletter {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    margin-top: 10px;
                                    input {
                                        width: 16px;
                                        height: 16px;
                                        margin: 0px;
                                        border-radius: 16px!important;
                                        cursor: pointer;
                                    }
                                       .news {
                                    font-size: 14.2px;
                                    font-weight: 400;
                                    color: rgb(80, 80, 80);
                                    letter-spacing: 0.3px;
                                    
                                }
                                }
                             
                            }
                        }
                        .express_checkout_title {
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            .divider {
                                height: 0.7px;
                                background-color: lightgray;
                                width: 15%;
                            }
                            span {
                                width: 100%;
                                font-size: 13.7px;
                                font-weight: 500;
                                color: rgb(51,51,51);
                                text-align: center;
                            }
                        }
                        .buttons {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 20px;
                            .paypal {
                                width: 50%!important;
                                position: relative;
                                margin-left: auto;
                                margin-right: auto;
                                .test {
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    background: #c3baba;
                                    top: 0;
                                    z-index: 200;
                                }
                            }
                            .google {
                                width: 50%;
                                .google-pay-button-container {
                                    width: 100%!important;
                                    .gpay-card-info-container , .black , .en {
                                        width: 100%!important;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }

                            }
                        }
                    }
                }
        
                .stepper {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 17px 0px;
                    flex-wrap: wrap;
                    .stepper_items {
                        display: flex;
                        align-items: center;
                        gap: 3px;
                      
                        color: #6f6f6f;

                        .icon{
                            font-size: 18px;
                        }
                        span {
                            margin: 0px;
                                    font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                            cursor: pointer;
                            font-weight: 300;
                            font-size: 12.2px;
                            cursor:text;
                        }
                        .done {
                            color: blue;
                            cursor: pointer;
                        }
                        .active {
                            color: rgb(40, 40, 40);
                            font-weight: 600;
                            cursor:text;

                        }
                    }
                }
        }
    }
    .section_open_infosCart {
        display: none;
    }
    .order_infos_cart ,  .infos_cart_open {
        width: 43%;
        height: 100%;
        background-color: rgb(250, 250, 250);
        border-left: 1.3px solid lightgray;
        /* position: sticky; */
        top: 0;
        /* height: 100vh*/
        height: initial;
        .infos_container {
            padding-right: 5vw;
            padding-left: 3.6vw;
            padding-top: 5vw;
            max-width: calc(462px + 5vw);
            padding-inline-end: 5vw;
            padding-inline-start: 3.6vw;
            display: flex;
            flex-direction: column;
            position: sticky;
            top: 0;
            .commande_header {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                .num_cmd {
                    font-size: 14px;
                    font-weight: 600;
                    color: #575757;
                    letter-spacing: 0.2px;
                }
            }
            .paragraph_thanks {
                margin: 0px;
                font-size: 14.5px;
                line-height: 22px;
                color: #5d5d5d;
                .name_user {
                    font-size: 16Px;
                    font-weight: 700;
                    text-transform: capitalize;
                }
            }
            .links_after_commande {
                display: flex;
                gap: 20px;
                align-items: center;
                margin-top: 25px;
                .link {
                    color: rgb(0, 130, 229);
                    cursor: pointer;
                    font-size: 14.5px;
                }
                button {
                    min-width: 160px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(0, 130, 229);
                    border-radius: 5px;
                    color: white;
                    font-size: 14.5px;
                    padding: 20px;
                    cursor: pointer;
                    transition: 0.4s ease;
                    outline: 0px;
                    border: 0px;
            }
            button:hover {
                background-color: rgb(0, 81, 144);
            }
        }
            h3 {
                font-size: 22px;
                color:black;
                font-weight: 500;
                margin-bottom: 15px;
            }
        .carte_items{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            border-bottom: 1.3px solid lightgray;
            padding-bottom: 15px;
            .cart__item-details {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .cart__item-left {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    .cate_image {
                        padding: 0px 11px;
                        border-radius: 10px;
                        border: 1px solid lightgray;
                        display: flex;
                        justify-content: center;
                        position: relative;
                        img {
                            max-height: 55px;
                        }
                        .quantity {
                            position: absolute;
                            top: 0;
                            margin-top: -13px;
                            margin-right: -5px;
                            right: 0;
                            padding: 4px 8px;
                            background-color: gray;
                            color: white;
                            border-radius: 50%;
                            font-size: 10.5px;
                            font-weight: 500;
                        }
                    }
                    p {
                        margin: 0px;
                                font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                        color: rgb(47,47,47);
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        font-size: 14px;
                        text-transform: capitalize;

                    }
                    span {
                                font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                        color: rgb(110,110,110);
                        font-weight: 400;
                        font-size: 12px;
                        text-transform: capitalize;
                        margin-top: -10px;
                        letter-spacing: 0.3px;
                    }
                }
                .cart__item-right {

                    span {
                                font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                        font-size: 14px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        font-weight: 500;
                        color: rgb(47,47,47);

                    }

                }
            }
            }
            .items_total{
                padding: 30px 0px;
                display: flex;
                flex-direction: column;
                gap: 13px;
                border-bottom: 1.3px solid lightgray;

                .section {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                            font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                    align-items: center;
                    span {
                             font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                        font-size: 14.2px;
                        letter-spacing: 0.3px;
                        color:rgb(89, 89, 89);
                        font-weight: 400;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }
                    #icon {
                        font-size: 18px;
                        cursor: pointer;
                    }
                    .subtotal {
                                font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                        font-size: 14px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        font-weight: 500;
                        color: rgb(47,47,47);
                       

                    }
                }
            }
            .total {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px 0px;
                border-bottom: 1.3px solid lightgray;
                span {
                        font-weight: 500;
                        color: rgb(47,47,47);
                        letter-spacing: 0.5px;
                        font-size: 15px;

                }
                .total_item {
                    font-size: 26px;
                }
                
            }
        }
    }
 

}
input[type="checkbox"]:checked {
    box-shadow: 0 0 0 0.4142857142857143em rgb(98,78,78) inset;
    background-color: white !important;;
    -webkit-appearance: none;
    
}
.paypal-powered-by{    display: none!important;
}
.MuiFilledInput-root {
    background-color: white !important;
    height: 47px!important;
    overflow: hidden!important;
}
.MuiFilledInput-root{
    border: 1Px solid rgba(85, 85, 85, 0.22)!important;
    border-radius: 5px;
    border-radius: 7px!important;


}
.MuiFilledInput-underline:before {
    border-bottom: 0px!important;
}
.MuiFilledInput-underline:before {
    border-radius: 5px!important;
}
.MuiFilledInput-underline::after {
    border: 1px solid rgb(82, 59, 17)!important;
    width: 98%;
    margin-left: auto;
    margin-right: auto;

}
.MuiInputLabel-filled.MuiInputLabel-shrink{
    color:rgb(90, 80, 63)!important; 
    font-size: 16px!important;
    letter-spacing: 0.3px;
    font-weight: 400!important;

}
.MuiFilledInput-input {
    color: rgb(82, 56, 9)!important;
}
.MuiSelect-filled.MuiSelect-filled{
    color: rgb(82, 56, 9)!important;
    font-size: 14.4px;
    font-weight: 500!important;
}
.MuiSelect-select:focus {
    background-color: rgb(244, 244, 244)!important;
    border-radius: 7px!important;
    height: 50px!important;

}
.MuiInputLabel-filled {
    color:rgb(90, 80, 63)!important; 
    font-size: 14px!important;
    font-weight: 400!important;
    margin-top: -2px;
}
.MuiFormControl-root {
    width: 100%;
}
.numberPhone {
    height: 70%;
    width:95%;
    position: absolute;
    top: 3px;
    left: 10px;
    max-height: 100%;
    margin: auto;
    border: 0px;
    z-index: -1;
}
.numberPhone:focus {
    outline-width: none;
}
.number_phone {
    position: relative;
    width: 100%;
    height: 47px;
    overflow: hidden;
}

.newsletter {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    input {
        width: 16px;
        height: 16px;
        margin: 0px;
        border-radius: 16px!important;
        cursor: pointer;
    }
       .news {
    font-size: 14.2px;
    font-weight: 400;
    color: rgb(80, 80, 80);
    letter-spacing: 0.3px;
    
}
}
.MuiFormHelperText-root.Mui-error {
    font-size: 14px;
    font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 400;
    margin: 0px;
    margin-left: 2px;
}

@media screen and (max-width:1000px) {
    .order {
        flex-direction: column-reverse;
        .order_infos_cart{
            position: relative!important;
            width: 100%!important;
            transform: translateY(-100%);
            max-height: 0px;
            height: auto;
            transition:  max-height 0.3s ease-out , transform 0.3s ease-out;
            overflow: hidden;
            .infos_container {
                margin: auto;
                max-width: 700px;
               
            }
        }
        .infos_cart_open{
            position: relative!important;
            width: 100%!important;
            max-height: 1000px!important;
            transform: translateY(0);
            height: auto;
            transition:   max-height 0.3s ease-in , transform 0.5s ease-in ;
            z-index: 1;
            .infos_container {
                margin: auto;
                max-width: 700px;
               
            }
        }
        .section_open_infosCart{
            display: flex;
            width: 100%;
            background-color: rgb(246,246,246);
            padding: 10px 0px ;
            .container{
                max-width: 700px;
                margin: auto;
                width: 90%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .adapter {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    flex-wrap: wrap;
                    width: 75%;
                    span {
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        color: rgb(72, 54, 11);
                        font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                        font-size: 14px;
                        font-weight: 400;
                    }
                     #shop {
                        color: $colorTextBody;
                     }
                }
              
                 p {
                    font-family: "Segoe UI",'Roboto',Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                    color: rgb(47, 47, 47);
                    width: max-content;
                    font-size: 17px;
                    font-weight: 600;
                 }
            }
        }
        .order_stepper {
            width: 100%;
            z-index: 100;

            .stepper_container {
                max-width: 700px;
                margin: auto;
                .logo {
                    display: none;
                }
                .inforamtion_section .express_checkout .form_infos .form_infos_details .title_wrapper {
                    flex-wrap: wrap;
                    span {
                        line-height: 40px;
                    }
                }
            }
        }

    }
    
}
@media screen and (max-width:500px) {
    .order .order_stepper .stepper_container .buttons {
        flex-direction: column-reverse;
        .next {
            margin-bottom: 23px;
            display: flex;
            justify-content: center;
            width: 100%;
        }
        .back {
            margin: 10px 0px;
            justify-content: center;
            width: 100%;


        }

    }

    
}
@keyframes load {

    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
    
}
@media screen and (min-height: 1200px) {
    
    .order {
        height: 100%;
    }
}