  @import "../../variables.scss";  
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

.account{
    height: 100%;
}
    .login_wrraper{
        width: 100%;
        // height: 100%;
        display: flex;
        justify-content: center;
        max-width: 1500px;
        margin: auto;
        .login_container{
            font-family:$primary-font-family;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 22px;
            width: 370px;
            padding: 10px 0px;
            max-width: 90%;
            margin: 80px 0px;
            width: 100%;
            span {
                color:$colorTextBody ;
                font-family: $primary-font-family;
                font-size: 24px;
                letter-spacing: 0.025em;
                font-weight: 500;
                padding: 20px 0px 0px 0px;
                margin: 0px 0px;
                line-height: 20px;
            }
            .logout {
                padding: 7px 20px;
                font-family: $primary-font-family;
                color: $colorTextBody;
                font-weight: 300;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                font-size: 14px;
                cursor: pointer;
                background-color: rgba(126, 68, 10, 0.175);
                border: 1px solid $color-references;
                transition: 0.2s ease;
            }
            .logout:hover {
                background-color: white;
            }
            .login_body{
                display: flex;
                justify-content: space-between;
                width: 100%;
                .login_body_left{
                    width: 50%;
                    h2 {
                        font-size: 19px;
                        font-family: 'Montserrat', sans-serif;
                        color: rgb(87, 62, 14);;
                        letter-spacing: 0.05rem;
                        font-weight: 600;
                        line-height: 1;
                    }
                    span {
                        font-size: 14px;
                        color: $colorTextBody;
                        font-weight: 400;
                    }
                }
                .login_body_right{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 50%;
                    align-items: center;
                    h5 {
                        font-size: 15px;
                        font-family: 'Montserrat', sans-serif;
                        color: rgb(87, 62, 14);;
                        letter-spacing: 0.05rem;
                        font-weight: 600;
                        line-height: 1;
                    }
                    span {
                        font-size: 13px;
                        font-weight: 300;
                        color: $colorTextBody;
                        margin: 0px;
                        padding: 0px;
                    }
                    .name {
                        letter-spacing: 0.15em;
                        text-transform: uppercase;
                    }
                    .adress , .link {
                        font-weight: 500;
                        text-transform:initial;
                        font-size: 13.5px;
                    }
                    .link {
                        cursor: pointer;
                    }
                }
            }

        }
    
    }