@import '../../variables.scss';

.faq_page {
width: 100%;
height: 100%;
.faq_wrapper {
    display: flex;
    padding: 15px;
    justify-content: center;
    margin-top: 100px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    min-height: 900px;
    height: auto;
    padding: 10px 0px;
    .faq_container {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 15px 30px;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        margin: 35px 0px;
        .title {
            font-family: $primary-font-family;
            color: rgb(49, 31, 5);;
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0px 0px 25px 0px;
        }
        .faq_left  {
            display: flex;
            flex-direction: column;
            gap: 20px;
           width: 90%;
           max-width: 1000px;
           img {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
            padding: 25px 0px;
           }
           li {
            margin: 30px 0px;
            list-style: disc;
           }
            .faq_title_section {
                gap: 10px;
                display: flex;
                flex-direction: column;
                .long {
                    font-size: 14px;
                    margin: 0px 0px 15px 0px;
                }
                h4 {
                    font-family:$primary-font-family ;  
                    font-size: 18px;
                    color: rgb(105, 73, 28);
                    margin: 0px;
                    font-weight: 600;
              }
              span {
                font-size: 13.5px;
                font-family: $primary-font-family;
                color: rgb(49, 31, 5);
                font-weight: 500;
                line-height: 20px;
              }
              .link {
                font-size: 13.5px;
                font-family: $primary-font-family;
                color: rgb(49, 31, 5);
                font-weight: 700;
                line-height: 20px;
                border-bottom: 1px solid rgb(49, 31, 5);;
              }
              .question {
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 10px;
              }

              .response {
                height: 0px;
                visibility: hidden;
                overflow: hidden;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                opacity: 0;
                height: 0;
                transition: opacity .3s cubic-bezier(.25,.46,.45,.94),height .3s cubic-bezier(.25,.46,.45,.94);
                margin-bottom: 40px;
             

              }
              .response_open {
                margin-bottom: 40px;
                margin-top: 30px;
                height: auto;
                visibility: visible;
                opacity: 1;
                transition: opacity 1s cubic-bezier(.25,.46,.45,.94),height .35s cubic-bezier(.25,.46,.45,.94);
              }
              .icon {
                padding: 5px;
                border-radius: 50%;
                font-size: 13px;
                background-color: rgb(227, 227, 227);
            }

              .contact {

                font-weight: 700;
                color: rgb(49, 31, 5);

              }
            }
      

        }

    }
}


}
@media screen and (max-width:450px) {
    .faq_guide .faq_wrapper .faq_container .faq_left .faq_title_section h4 {
        font-size: 20px;
    }
    
}
@media screen and (max-width:500px) {
    .dilevery .faq_wrapper .faq_container .faq_left{
        width: 100%;
    }
    
}

