@import "../variables.scss";

.search-page {
    width: 100%;
    height:  max-content;
    .navbar{
        width: 100%;
        height: auto ;
    }
                .pagination {
                    width: 90%;
                    margin-left: auto;
                    margin-right: auto;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    gap: 20px;
            }
 

 
}

