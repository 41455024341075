@import '../../variables.scss';

.contact {
width: 100%;
height: 100%;
.contact_wrapper {
    height: 100%;
    display: flex;
    padding: 15px;
    justify-content: center;
    margin-top: 100px;
    padding: 10px 0px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto; 
    min-height: 700px;
    .contact_container {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 15px 30px;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        margin: 35px 0px;
        .title {
            font-family: $primary-font-family;
            color: rgb(49, 31, 5);;
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0px 0px 25px 0px;
        }
        .container {
            width:100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            .valide {
                width: 80%;
                height: auto;
                padding: 10px 0px;
                border: 1px solid ;
                color: #56ad6a;
                background-color: #ecfef0;
                border-color: #56ad6a;
                font-family: $primary-font-family;
                span {
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    gap: 15px;
                    letter-spacing: 1px;
                    margin-left: 15px;

                }
            }
            .errors {
                width: 80%;
                height: auto;
                padding: 10px 0px;
                border: 1px solid #d02e2e;
                background-color: #fff6f6;
                color: #d02e2e;
                font-family: $primary-font-family;
                span {
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    gap: 15px;
                    letter-spacing: 1px;
                    margin-left: 15px;

                }
            }
            .inputs_wrapper{
                width: 80%;
                display: flex;
                gap: 30px;
            }
            .items {
                width: 80%;
                display: flex;
                flex-direction: column;
                gap: 8px;
                .input {
                    width: 100%;
                    padding: 0px;
                    margin: 0px;
                    height: 20px;
                    border: 1px solid $border ;
                    color: rgb(136, 101, 36);
                    font-weight: 500;
                    font-size: 16px;
                    font-family: "Montserrat", sans-serif;
                    letter-spacing: 0.06em;
                    padding: 10px 0px 10px 10px;
                    
    
                }
                label {
                    font-family: $primary-font-family;
                    color: rgb(109, 77, 17);
                    font-size: 12px;
                    letter-spacing: 1px;
                }
                .textArea {
                    resize: vertical;
                    width: 100%!important;
                    min-height: 100px;
                    max-width: 100%;
                }
            }
            .politique{
                font-family: $primary-font-family;
                color:  rgba(115, 85, 29, 0.93);;
                font-size: 13px;
            }
            .send {
                height: 45px;
                background-color: rgba(115, 85, 29, 0.93);
                color: white;
                font-size: 13.5px;
                letter-spacing: 0.25rem;
                font-weight: 600;
                cursor: pointer;
                text-transform: uppercase;
                border: 0px;
                font-family: "Montserrat", sans-serif;
                min-width: 150px;
            }
            .send:hover {
                box-shadow: inset 400px 0 0 0 rgba(115, 85, 29, 0.231);
                transition: box-shadow 0.6s ease 0s;
                transition-delay: 0.25s;
                background-color: rgba(115, 85, 29, 0.756);
            }
       
        }
   
    }
}
}

@media screen and (max-width: 769px) {

    .contact .contact_wrapper .contact_container .container .inputs_wrapper {
        flex-direction: column;
    }
    .contact .contact_wrapper .contact_container .container .items {
        width: 100%;
    }
    .contact .contact_wrapper .contact_container .container .text {
        width: 80%!important;
    }
    
}

@media screen and (max-width: 500px) {
    .contact .contact_wrapper .contact_container .container .inputs_wrapper {
        width: 100%;
    }
    .contact .contact_wrapper .contact_container .container .text {
        width: 100%!important;
    }
}