@import  '../../variables.scss' ;
.collection-sidebar {
    width: 20%;
    position: sticky;
    display: flex;
    justify-content: center;
    float: left;
    top: 160px;
    box-sizing: border-box;
    position: -webkit-sticky;
    
    .filter-form {
        width: 95%;
        padding: 10px;
        max-width: 220px;
        .filter-block  {
            width: 100%;
            height: 40px;
            display: none;
            align-items: center;
            justify-content: space-between;
            transition: transform 0.85s cubic-bezier(0.165, 0.84, 0.44, 1) 0.255s, opacity 0.85s cubic-bezier(0.165, 0.84, 0.44, 1) 0.33s;
            span {
                font-family: $primary-font-family;
            }
        }
        .collection-sidebar__group {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .filter_button{
                width: 90%;
                background-color: white;
                border: 0px;
                display: flex;
                align-items: center;
                font-family: $primary-font-family;
                font-weight: 500;
                letter-spacing: 0.3em;
                text-transform: uppercase;
                color: $colorTextBody ;
                padding: 0px 0px 12px 10px;
                justify-content: space-between;
                cursor: pointer;
                span {
                    font-weight: 600;
                    font-size: 0.75em;
                }
                #icon-down {
                    font-size: 20px;
                    font-weight: 200;
                }
            }
            .filter_disponibilte  , .filter_disponibilte_hide {
                width: 95%;
                height: 40px;
                .tag-list {
                    padding: 0px;
                    list-style: none;
                    text-rendering:optimizeLegibility;
                    margin: 0px;
                    margin-left: 5px;
                    li {
                        margin-bottom: 0.25em;
                        box-sizing: border-box;
                        width: inherit;
                        .checkbox_wrapper {
                            display: flex;
                            width: 100%;
                            align-items: center;
                            font-family: $primary-font-family;
                            margin-bottom: 10px;
                            cursor: pointer;
                            span {
                                margin-left: 10px;
                                font-size: 14px;
                                color: $colorTextBody;
                                padding-bottom: 2px;
                                border: 1.7px solid transparent;
                            }
                            input[type=checkbox] {
                                position: relative;
                                  cursor: pointer;
                                  border: none!important;
                                  -webkit-appearance: none;
                                  -moz-appearance: none;
                                  appearance: none;
                                  margin-right: 14px;
                           }
                           input[type=checkbox]:before {
                            border: 1px solid;
                            border-color: #603810;
                            height: 14px;
                            width: 14px;
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            box-sizing: border-box;
                            
                   }
                       input[type=checkbox]:checked:after {
                        height: 8px;
                        width: 8px;
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 3px;
                        background-color: #624e4e!important;
                   } 
                   .checkbox_wrapper  input[type=checkbox]:checked:after ,  span {
                    font-weight: 900;
                   }
                        }
                        .checkbox_wrapper:hover span{
                            border-bottom: 1.7px solid $bg-icons-hover;
                            font-weight: 900;
                        }
                        
                    }
                }
                .collection-fliter_price{
                    width: 93%;
                    height: max-content;
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .checkbox_wrapper_price {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 0px;
                        .price_range {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            .price-range_display {
                                width: 95%;
                                height: 100%;
                                display: flex;
                                justify-content: space-between;
                                font-family: $primary-font-family;
                                span {
                                    font-size: 14px;
                                    color: $colorTextBody;
                                }
                            }
                            .price-range_slider {
                                width: 95%;
                                height: 100%;
                                display: flex;
                            }
                        }
                    }
                }
         
            }
         
            .filter_disponibilte_hide {
                transform: scaleY(1);
                transform-origin: left bottom;
                height: 100%;
                transition: all 0.4s linear;;
                opacity: 1;
            }
            .filter_disponibilte {
                transform-origin: left top;
                transform: scaleY(0);
                height: 0px;
                transition: all 0.4s linear;;
                opacity: 0.5;
                
            }
        }
        .bar {
            width: 90%;
            height: 2px;
            background-color: $colorTextBody;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px ;
            margin-bottom: 10px ;
         

        }
        .color_filter {
            width: 95%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 40px;
            .filter_button{
                width: 95%;
                background-color: white;
                border: 0px;
                display: flex;
                align-items: center;
                font-family: $primary-font-family;
                font-weight: 500;
                letter-spacing: 0.3em;
                text-transform: uppercase;
                color: $colorTextBody ;
                padding: 0px 0px 12px 10px;
                justify-content: space-between;
                cursor: pointer;
                span {
                    font-weight: 600;
                    font-size: 0.75em;
                }
                #icon-down {
                    font-size: 20px;
                    font-weight: 200;
                }
            }
            .colors_disponibilities  , .colors_disponibilities_hide{
                width: 98%;
                background-color: white;
                display: flex;
                flex-wrap: wrap;
                gap: 11px;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
                transform: scaleY(0);
                height: 200px;
                background-color: transparent;
                .advenced-filter {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px;
                    li {
                        margin: 0px;
                        padding: 3px;
                        border: 2px solid rgba(0, 0, 0, .1);
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                 
                    img {
                        width: 22px;
                        height: 22px;
                        object-fit: contain;
                        border-radius: 50%;
                   

                    }
                }
             
            }
               
            .colors_disponibilities{
                transform: scaleY(1);
                transform-origin: left top;
                height: 100%;
                transition: all 0.4s linear;;
                opacity: 1;
            }
            .colors_disponibilities_hide {
                transform-origin: left top;
                transform: scaleY(0);
                height: 0px;
                transition: all 0.4s linear;;
                opacity: 0.5;
                background-color: transparent;
                
            }
    
        }
        
    } 
}




  @media screen and (max-width: 1075px) {
    .collection-sidebar .filter-form .collection-sidebar__group .filter_button span {
        letter-spacing: 0.25em;
    }
    .collection-sidebar .filter-form .color_filter .filter_button span {
        letter-spacing: 0.25em ;
    }
  }

  @media screen and (max-width: 900px) {

    .collection-sidebar .filter-form .collection-sidebar__group .filter_button 
    {

    span {
        letter-spacing: 0.15em;
        font-size: 0.65em;
    }
    #icon-down {
        font-size: 17px;

    }
}
 

  }

  @media screen and (max-width:900px) {
    .collection-sidebar .filter-form .color_filter .filter_button {
        span {
            letter-spacing: 0.15em!important;
            font-size: 0.65em!important;
        }
        #icon-down {
            font-size: 17px;
        }    

    } 

  }

  @media screen and (max-width:769px) {
    .hide_sideBar {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        background-color: rgba(204, 201, 201, 0.305);
        z-index: 105;
    }
    .collection-sidebar {
        position: fixed;
        height: 100%;
        width: 350px;
        left: -350px;
       background-color: rgb(152, 114, 57);
        top: 0;
        transition: 0.45s;
        z-index:110;
        .filter-form {
            max-width: 100%;
       
            .filter-block {
                width: 95%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 25px;
                display: flex;
                span {
                    font-size: 22px;
                    color: #ffedcf;
                }
                #icon-close {
                    color: #ffedcf;
                }
            }
            .collection-sidebar__group {
                .filter_button {
                    background-color: transparent;
                    span {
                        color: #ffedcf;
                    }
                    #icon-down {
                        color: #ffedcf;
                    }
                }
                .filter_disponibilte_hide {
                    .collection-fliter_price {
                        .checkbox_wrapper_price {
                            .price_range {
                                .price-range_display {
                                    span {
                                        color:#ffedcf;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .bar {
                background-color: #fed388;
            }
            .color_filter {
                .filter_button {
                    background-color: transparent;
                    span {
                        color: #ffedcf;
                    }
                    #icon-down {
                        color: #ffedcf;
                    }
                }
                .colors_disponibilities {
                    background-color: transparent;
                    gap: 25px;
                    .advenced-filter li {
                        border: 2px solid rgb(255 255 255);
                    }
                }
            }
        
        }
    }
    .MuiSlider-track {
        color: #fed388 ;
    }
    .MuiSlider-thumb {
        color: #fed388 ;

    }
 
    .collection-sidebar .filter-form .collection-sidebar__group .filter_disponibilte .tag-list li .checkbox_wrapper .checkbox_wrapper input[type=checkbox]:checked:after, .collection-sidebar .filter-form .collection-sidebar__group .filter_disponibilte .tag-list li .checkbox_wrapper span, .collection-sidebar .filter-form .collection-sidebar__group .filter_disponibilte_hide .tag-list li .checkbox_wrapper .checkbox_wrapper input[type=checkbox]:checked:after, .collection-sidebar .filter-form .collection-sidebar__group .filter_disponibilte_hide .tag-list li .checkbox_wrapper span {
        color: #ffedcf;

    }
    .collection-sidebar .filter-form .collection-sidebar__group .filter_disponibilte .tag-list li .checkbox_wrapper input[type=checkbox]:before, .collection-sidebar .filter-form .collection-sidebar__group .filter_disponibilte_hide .tag-list li .checkbox_wrapper input[type=checkbox]:before {
       border-color: #ffedcf;
    }
    .collection-sidebar .filter-form .collection-sidebar__group .filter_disponibilte .tag-list li .checkbox_wrapper input[type=checkbox]:checked:after, .collection-sidebar .filter-form .collection-sidebar__group .filter_disponibilte_hide .tag-list li .checkbox_wrapper input[type=checkbox]:checked:after {
        background-color : #fed388!important;
    }
    .collection-sidebar .filter-form .collection-sidebar__group .filter_disponibilte .tag-list li .checkbox_wrapper:hover span, .collection-sidebar .filter-form .collection-sidebar__group .filter_disponibilte_hide .tag-list li .checkbox_wrapper:hover span {
        border-bottom:1.7px solid  #fed388;
    }
    
  }

  @keyframes  animate-acceuil-link {
    from {
        opacity: 0.5;
      transform: translateY(40px) ;
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@media screen and ( max-width:360px) {
    .collection-sidebar {
        width: 340px;
    }
    
 }
 @media screen and ( max-width:350px) {
    .collection-sidebar {
        width: 330px;
    }
    
 }
 @media screen and ( max-width:340px) {
    .collection-sidebar {
        width: 320px;
    }
    
 }
 @media screen and ( max-width:330px) {
    .collection-sidebar {
        width: 300px;
    }
    
 }
 @media screen and ( max-width:310px) {
    .collection-sidebar {
        width: 290px;
    }
    
 }