@import '../../src/variables.scss';
.pagination {
    padding: 0px;
    margin-bottom: 120px;
    .page-item {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        cursor: pointer;
        .page_link{
            font-family: $primary-font-family;
            font-size: 20px;
            font-weight: 500;
             color:  $colorTextBody
        }
        
    }
}