@import '../variables.scss';

.navbar_media--bg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: rgba(204, 201, 201, 0.305);
    z-index: 35;
}
.navbar_media_container {
    width: 350px;
    height: 100%;
    background-color: white;
    z-index: 40;
    animation: navbar-media 0.5s ease;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    overflow: hidden;
    position: fixed;
    top: 0;
    font-family: $primary-font-family;
    left: 0;
    box-shadow: 0 0 150px #0000001a;
    .content {
        width: 92%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        
        .top_bar {
            width: 94%;
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin-right: auto;
            margin-left: auto;

            .currency {
                padding: 5px 10px;
                cursor: pointer;
                #flag{
                    font-size: 30px;
                    margin-right: 10px;
                }
                #nameFlag {
                    font-weight: 500;
                    font-size: 22px!important;
                    color: black;
                    font-family: $primary-font-family;
                }
            }
            #close_icon {
                font-size: 16px;
                color: $color-icons;
                padding: 5px 10px;
                color: black;

            }
        }
        ul {
            padding: 0;
            text-rendering: optimizeLegibility;
        }
        .mobil-navigation {
            width: 100%;
            margin-top: 90px;
            font-family: $primary-font-family;
            overflow-x: hidden;


            .mobil-nav_item {
                position: relative;
                display: block;
                line-height: 1.4;
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                transition: transform .85s cubic-bezier(.165,.84,.44,1) .255s,opacity .85s cubic-bezier(.165,.84,.44,1) .33s;
           
                .mobil-nav_sublist {
                    width: inherit;
                    display: flex;
                    justify-content: space-between;
                    height: inherit;
                    display: flex;
                    align-items: center;
                      height: 50px;

                    .mobil-nav_link {
                        font-size: 15px;
                        text-decoration: none;
                        color: black;
                        letter-spacing: 0.2em;
                        font-weight: 400;
                        padding:  0px 0px 0px 6px;
                        width: 80%;
                        display: flex;
                        align-items: center;
                     
                }   #icon_list {
                    width: 10%;
                    font-size: 16px;
                    color: black;
                    margin-left: auto;
                    font-weight: 200;
                    transition: 0.3s ease-in-out;

                }
        }  
        .Linklist {
            width: inherit;
            display: flex;
            animation: list-links 0.5s ease-out ;
            transition: transform .5s cubic-bezier(.25,.46,.45,.94);
            flex-direction: column;
            a {
                text-decoration: none;
            }
            li {
                color: black;
            }
            .mobile-nav__link {
                height: 30px;
                display: flex;
                align-items: center;
                text-decoration: none;
                color: rgba(0, 0, 0, 0.754);
                font-size: 15px;
                padding: 0px 0px 0px 8px;
            }
        }
        .Linklist-close {
                animation: list-links-close 0.3s ease-in;
                height: 0px;
                a {
                    display: none;
                }
        }
        
        .mobil-nav_link {
            font-size: 15px;
            text-decoration: none;
            color: black;
            letter-spacing: 0.2em;
            font-weight: 400;
            padding:  0px 0px 0px 15px;
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
    }
    .mobil-nav_link:active {
        background-color: $bg-links-visited;
    }

            }
            .social-media-links {
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                a {
                    text-decoration: none;
                }
                #icon {
                    font-size: 30px;
                    color: black;
                }
            }

        }
    }
}


@keyframes navbar-media {

    from {
        width: 0;
    }
    to {
        width: 350px;
    }
    
}

@media screen and (min-width: 768px) {

    .navbar_media {
        display:none
    }
    
}
@keyframes list-links {

    from {
        opacity: 0;
        transform: translateY(30px);
  
      }
    
      to {
        opacity: 1; 
        transform: translateY(0);
      } 
    
}
@keyframes list-links-close {

    from {
        transform: translateY(30px);
        height: 80px;
      }
    
      to {
        transform: translateY(0);
        display: none;
        height: 0px;
       
      } 
    
}

@keyframes  animate-acceuil-link {
    from {
        opacity: 0.5;
      transform: translateY(60px) ;
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


 @media screen and (min-width: 769px) {
    .navbar_media--bg { 
        display: none ;
    }
    .navbar_media_container {
        display: none;
    }
 }

 @media screen and ( max-width:360px) {
    .navbar_media_container {
        width: 340px;
    }
    
 }
 @media screen and ( max-width:350px) {
    .navbar_media_container {
        width: 330px;
    }
    
 }
 @media screen and ( max-width:340px) {
    .navbar_media_container {
        width: 320px;
    }
    
 }
 @media screen and ( max-width:330px) {
    .navbar_media_container {
        width: 300px;
    }
    
 }
 @media screen and ( max-width:310px) {
    .navbar_media_container {
        width: 290px;
    }
    
 }


 @media  screen and (max-width:450px)  {
    .navbar_media_container .content .mobil-navigation .mobil-nav_item .mobil-nav_link {
        height: 35px;
    }
    
 }
 a {
    text-decoration: none!important;
 }