@import "./variables.scss";
.app , .app_scrolled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.app_scrolled {
  overflow-y: hidden;
}

body {
    margin: 0;
    padding: 0;
}

/// poure le data on hovering

[data-title] {
    position: relative;
  }
  
  [data-title = "Ajouter au panier" ]:hover::before {
    content: attr(data-title);
    position: absolute;
    bottom: 37px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 2px;
    background: #000;
    color: #fff;
    font-size: 12px;
    font-family: sans-serif;
    white-space: nowrap;
    z-index: 50!important; /*optional styling*/
    font-family: $primary-font-family;
    margin-left: -40px;
  }
  [data-title = "quickShop" ]:hover::before {
    content: attr(data-title);
    position: absolute;
    bottom: 37px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 2px;
    background: #000;
    color: #fff;
    font-size: 12px;
    font-family: sans-serif;
    white-space: nowrap;
    z-index: 50!important; /*optional styling*/
    font-family: $primary-font-family;
    margin-left: -25px;
  }

  @media screen and (max-width:500px) {
    [data-title = "Ajouter au panier" ]:hover::before {
      content: attr(data-title);
      margin-left: -40px;
      font-size: 10px;
      bottom: 40px;
    }
    
  }

  @media screen and (max-width:500px) {
    [data-title = "quickShop" ]:hover::before {
      content: attr(data-title);
      position: absolute;
      display: inline-block;
      padding: 3px 6px;
      border-radius: 2px;
      background: #000;
      color: #fff;
      font-size: 12px;
      font-family: sans-serif;
      white-space: nowrap;
      z-index: 50!important; /*optional styling*/
      font-family: $primary-font-family;
      margin-left: -25px;
      font-size: 10px;
      bottom: 40px;
    }
    
  }
