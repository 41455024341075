@import url('https://fonts.cdnfonts.com/css/futura-pt');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');
$header-background-color : #9d6b21ba ;
$header-text-color : #1a1113 ;
$header-font-size : 16px;
$header-font-family : 'Futura PT', sans-serif;
$currency-font-family :  'Montserrat', sans-serif;
$primary-font-family : 'Montserrat', sans-serif;
$color-icons: #1a1113 ;
$bg-links-visited : #f3f3f389;
$bg-icons: #c2a394;
$bg-icons-hover: #8d6a58;
$color-references:rgba(157, 107, 33, 0.7294117647);
$colorTextBody: rgb(136, 101, 36);
$border : rgb(134, 87, 18);




